<div *loggedRender>
  <a class="favourites" (click)="toggleFavouriteGame()" *ngIf="favouriteGames.includes(gameId);else addToFavourites">
    <img src="assets/icons/icon_fav_remove_40x40.svg">
    <span i18n>Remove from favourites</span>
  </a>
  <ng-template #addToFavourites>
    <a class="favourites" (click)="toggleFavouriteGame()">
      <img src="assets/icons/icon_fav_add_40x40.svg">
      <span i18n>Add to favourites</span>
    </a>
  </ng-template>
</div>
