import { Component, ElementRef, Inject, Input, LOCALE_ID, NgZone, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { tournamentsTrx } from 'src/app/router-translation.labels';
import { TournamentDetailsService } from 'src/app/services/tournaments/tournament-details.service';
import { TournamentService } from 'src/app/services/tournaments/tournament.service';
import { CommonTournament, NetworkTournament, Tournament, TOURNAMENTTYPE } from 'src/app/services/tournaments/tournaments.models';
import { DetectDeviceService } from 'src/app/services/utils/detect-device.service';
import { BasePageComponent } from '../../base-page/base-page.component';

interface TournamentEndCountDown {
  days: number,
  hours: number,
  minutes: number,
  seconds: number
}

@Component({
  selector: 'app-tournament-info-box',
  templateUrl: './tournament-info-box.component.html',
  styleUrls: ['./tournament-info-box.component.scss']
})
export class TournamentInfoBoxComponent extends BasePageComponent {

  constructor(
    @Inject(LOCALE_ID) public locale: string,
    public router: Router,
    public tournamentDetailsService: TournamentDetailsService,
    public tournamentService: TournamentService,
    public detectDeviceService: DetectDeviceService,
    protected _ngZone: NgZone,
    // private cloneService: ClonerService,
  ) { super() }

  protected _tournament: CommonTournament;
  public countDown: TournamentEndCountDown;
  public isFinished: boolean = false;
  tournamentStartDateLocal: Date;
  tournamentEndDateLocal: Date;
  isUpcommingRefreshed: boolean = true;
  now: Date;
  game: string;

  @ViewChild('tournamentstartdate') tournamentStartDateElement: ElementRef;

  @Input() tournamentIndex: number;
  @Input() isLogged: boolean = false;
  @Input() isModalDisplay: boolean = false;

  @Input() set tournament(tournamentInput: CommonTournament) {
    this._tournament = tournamentInput;
    this.tournamentStartDateLocal = new Date(tournamentInput.startDate);
    this.tournamentEndDateLocal = new Date(tournamentInput.endDate);
    this.isUpcommingRefreshed = false;

    if (tournamentInput.games.length > 1) {
      let index = 0;
      this._ngZone.runOutsideAngular(() => {
        timer(0, 2000).pipe(takeUntil(this.unsubscribe)).subscribe(resp => {
          this.game = tournamentInput.games[index].name;
          index = (index + 1) % tournamentInput.games.length;
        })
      });

    }
    else {
      this.game = tournamentInput?.games[0]?.name
    }

    if ((!tournamentInput.isNetworkTournament && (tournamentInput as Tournament).tournamentType === TOURNAMENTTYPE.ACTIVE)
      || (tournamentInput.isNetworkTournament)
    ) {
      this.countDown = {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
      }
      this._ngZone.runOutsideAngular(() => {
        const source = timer(0, 1000).pipe(takeUntil(this.unsubscribe)).subscribe(resp => {
          this.now = new Date(Date.now());
          const diff = Math.max(0, Math.floor(Math.floor((new Date(this._tournament.endDate).getTime() - new Date().getTime()) / 1000)));

          let seconds = diff;
          let minutes = Math.floor(seconds / 60);
          let hours = Math.floor(minutes / 60);
          let days = Math.floor(hours / 24);

          hours = hours - (days * 24);
          minutes = minutes - (days * 24 * 60) - (hours * 60);
          seconds = seconds - (days * 24 * 60 * 60) - (hours * 60 * 60) - (minutes * 60);

          this.countDown.days = days;
          this.countDown.hours = hours;
          this.countDown.minutes = minutes;
          this.countDown.seconds = seconds;
          if (this.tournamentStartDateElement) {
            if (this.countDown.days > 0) {
              this.tournamentStartDateElement.nativeElement.innerHTML = `${this.countDown.days}d ${this.countDown.hours}h ${this.countDown.minutes}m`;
            }
            else {
              this.tournamentStartDateElement.nativeElement.innerHTML = `${this.countDown.hours}h ${this.countDown.minutes}m ${this.countDown.seconds}s`;

            }
          }
        })
      });
    }
    else if (!tournamentInput.isNetworkTournament && (tournamentInput as Tournament).tournamentType === TOURNAMENTTYPE.UPCOMMING) {
      this._ngZone.runOutsideAngular(() => {
        const source = timer(0, 5000).pipe(takeUntil(this.unsubscribe)).subscribe(resp => {
          this.now = new Date(Date.now());
          this.now.setSeconds(this.now.getSeconds() - 30)
          if (this.now >= this.tournamentStartDateLocal && !this.isUpcommingRefreshed) {
            this.isUpcommingRefreshed = true;
            this.tournamentService.forceUpdateStore();
          }
        })
      });
    }
  };

  get tournament(): CommonTournament {
    if (this._tournament.isNetworkTournament)
      return (this._tournament as NetworkTournament)
    else return (this._tournament as Tournament)
  }

  public isRegularTournament(): Tournament | undefined {
    return !this.tournament?.isNetworkTournament ? this.tournament as Tournament : undefined;
  }
  public isNotRegularTournament(): NetworkTournament | undefined {
    return this.tournament?.isNetworkTournament ? this.tournament as NetworkTournament : undefined;
  }

  ngOnInit(): void {
    this.isDesktop = this.detectDeviceService.isDesktop();
    this.prepareTournamentData();
  }

  getImageUrl() {
    return `url("${this.tournament.thumbnailMobile.url}")`;
  }

  prepareTournamentData() {
    const endDate = new Date(new Date().getTime() + (2 * 60 * 60 * 1000));

    if (!this._tournament.isNetworkTournament && (this._tournament as Tournament).tournamentType === TOURNAMENTTYPE.ACTIVE && this.isLogged) {
      this.tournamentDetailsService.getTournamentState((this._tournament as Tournament).ref).subscribe(resp => {
        this.isFinished = resp?.isFinished;
      })
    }

    if (this._tournament.isNetworkTournament) {
      if (new Date(this.tournament.endDate) < endDate) {
        this.isFinished = true;
      }
    }
  }

  checkAndOpen() {
    this.checkAndOpen && this.router.navigate([tournamentsTrx, 'id', this.tournament.cmsId]);
  }
}
