<div class="prize-boxes-wrapper">
  <ng-container *ngIf="showBoxes">
    <div class="label-container" (click)="expand()" [ngClass]="isDesktop ? 'desktop': 'mobile'" *ngIf="!isClaimed else showboxes" fxLayout="row" fxLayoutGap="9px" fxLayoutAlign="space-between center">
      <div fxLayoutAlign="center center" class="rectangle">
        <img src="assets/icons/icon-treasure-box-bonus.png" />
      </div>
      <p fxFlex="70" i18n>Open your treasure boxes!</p>
      <button *ngIf="isDesktop" fxFlex="20" round-button color="warn" filled="empty" (click)="expand()" i18n>Click to Open!</button>
      <div *ngIf="!isDesktop">
        <img src="{{licenceService.getKey('arrow-icon')}}" icon-btn size="large" alt="icon-arrow-right">
      </div>
    </div>
  </ng-container>
  <ng-template #showboxes>
    <ng-container *ngIf="!isSelectionPrizeVolatilityType else selectboxvolatilitytype">
      <div class="header" [ngClass]="isDesktop ? 'desktop': 'mobile'">
        <div class="category-title-h1" *ngIf="isDesktop" i18n>Treasure boxes</div>
        <div class="info" *ngIf="lotteryState !== lotteryStateEnum.FINISHED" i18n>Click on the box to open it.</div>
        <div *ngIf="prizeBoxLottery && !askForNextLevel" class="left-info"><span i18n>You have {{leftToOpen}} box left</span> </div>
        <div *ngIf="prizeBoxLottery && askForNextLevel" class="next-level-button"><button mat-button color="primary" (click)="getPrizeBoxes()" i18n>next level ></button></div>
      </div>
      <ng-container *ngIf="isDesktop">
        <ng-container *ngTemplateOutlet="closebuttons"></ng-container>
      </ng-container>
      <div class="bottom-wrapper" [ngClass]="isDesktop ? 'desktop': 'mobile'" *ngIf="prizeBoxLottery">
        <div class="prize-wrapper" fxLayoutAlign="center center">
          <div [ngStyle]="{'height.px': canvasHeight}" #animatedBox></div>
        </div>
      </div>
      <ng-container *ngIf="!isDesktop">
        <ng-container *ngTemplateOutlet="closebuttons"></ng-container>
      </ng-container>
    </ng-container>
  </ng-template>
</div>

<ng-template #closebuttons>
  <div class="buttons" fxLayoutAlign="center center" fxLayout="row">
    <button mat-stroked-button class="lemon-default-btn wheel-btn" (click)="close()" i18n>Close</button>
  </div>
</ng-template>

<ng-template #selectboxvolatilitytype>
  <div class="header" [ngClass]="isDesktop ? 'desktop': 'mobile'">
    <div class="category-title-h1" i18n>Treasure boxes</div>
    <div class="info" i18n>Please select 1 option.</div>
  </div>
  <div fxLayoutAlign="center center" class="containers-wrapper" fxLayout="row wrap" fxLayoutAlign="space-evenly start">
    <div class="container">
      <div class="spinner-level level" fxLayout="row" fxLayoutAlign="space-between start">
        <div class="spinner-container" style="height: 100%;" fxLayout="row" fxLayoutGap="16px">
          <div>
            <img src="assets/icons/icon-box-regular.png" alt="icon-gamification" />
          </div>
          <div class="label">
            <div class="title" i18n>Regular</div>
            <div class="subtitle" i18n>More, regular value boxes</div>
          </div>
        </div>
        <span flexs="1"></span>
        <a [routerLink]="treasureBoxInfoUrl" target="_blank" rel="noreferrer" routerLinkActive="active">
          <img src="assets/client-area/icon-info.svg" style="width: 40px">
        </a>
      </div>
      <button round-button filled="filled" color="warn" (click)="selectPrizeBoxType(0)" style="width: 100%; margin-top: 15px" i18n>Select</button>
    </div>
    <div class="container second">
      <div class="spinner-level level" fxLayout="row" fxLayoutAlign="space-between ">
        <div class="spinner-container" style="height: 100%;" fxLayout="row" fxLayoutGap="16px">
          <div>
            <img src="assets/icons/icon-box-high.png" alt="icon-gamification" />
          </div>
          <div class="label">
            <div class="title" i18n>High Value</div>
            <div class="subtitle" i18n>Fewer, higher value boxes</div>
          </div>
        </div>
        <span flexs="1"></span>
        <a [routerLink]="treasureBoxInfoUrl" target="_blank" rel="noreferrer" routerLinkActive="active">
          <img src="assets/client-area/icon-info.svg" style="width: 40px">
        </a>
      </div>
      <button round-button filled="filled" color="warn" (click)="selectPrizeBoxType(1)" style="width: 100%; margin-top: 15px" i18n>Select</button>
    </div>
  </div>
</ng-template>
