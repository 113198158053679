<div class="profile-body" [ngClass]="isDesktop ? 'desktop': 'mobile'">
  <div fxLayout="column">
    <ng-container *ngIf="size !== 'desktop-top' && size !== 'mobile-top'">
      <div class="top-container" fxLayout="row" fxLayoutAlign="space-between center" (click)="redirectToProfile()">
        <div fxflex fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="8px" style="overflow: hidden; padding-bottom: 5px;">
          <ng-container *ngIf="avatar">
            <div class="vip-avatar" *ngIf="userRole else regularAvatar">
              <div class="vip-img-content">
                <img class="vip-player-img" src="{{avatar}}" />
                <div class="role" i18n>vip</div>
              </div>
              <img class="vip-leef" src="{{licenceService.getKey('icon-vip-leef')}}" >
            </div>
            <ng-template #regularAvatar>
              <img class="regular-player-img" src="{{avatar}}" />
            </ng-template>
          </ng-container>
          <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="4px" style="overflow: hidden;">
            <div *ngIf="verified" class="verified" i18n>Verified</div>
            <div class="email" i18n>{{username}}</div>
          </div>
        </div>
        <div class="thumbnail {{size}}" (click)="redirectToProfile()">
          <div class="spinner-level level {{size}}">
            <div class="spinner-container" (click)="redirectToProfile()">
              <div class="spinner-background">
                <img *ngIf="gamificationIndex && showGamification" style="border: 0" src="assets/icons/lemons-levels/icon-lemon-level-{{gamificationIndex}}.svg" alt="icon-gamification" />
              </div>
              <mat-progress-spinner #spinnerlevel color="accent" mode="determinate" [value]="gamificationPercentFill" [diameter]="54">
              </mat-progress-spinner>
            </div>
            <div class="label-level-name" [ngClass]="{'vip-label': userRole}" i18n>{{playerLoyalityName}}</div>
          </div>
        </div>
      </div>
    </ng-container>
    <div class="content {{size}}" fxLayoutAlign="space-between start" fxLayout="row">
      <ng-container *ngIf="size === 'desktop-top' || size === 'mobile-top'">
        <div class="profile-name" fxLayoutAlign="center start" fxLayout="column">
          <div class="header-content" fxLayoutAlign="center start" fxLayout="row" fxLayoutGap="6px">
            <div class="header-profile-thumbnail {{size}}" [ngClass]="{'mobile-vip-thumbnail': userRole && size === 'mobile-top'}" (click)="redirectToProfile()">
              <div class="vip-avatar" *ngIf="userRole else regularAvatar">
                <div class="vip-img-content">
                  <img class="vip-player-img" src="{{avatar}}" />
                  <div class="role" i18n>vip</div>
                </div>
                <img class="vip-leef" src="{{licenceService.getKey('icon-vip-leef')}}" >
              </div>
              <ng-template #regularAvatar>
                <div class="regular-avatar">
                  <img class="regular-player-img" src="{{avatar}}" />
                </div>
              </ng-template>
            </div>
            <div class="header-thumbnail {{size}}" (click)="redirectToProfile()">
              <div class="spinner-level level {{size}}">
                <div class="spinner-container" (click)="redirectToProfile()">
                  <div class="spinner-background">
                    <img *ngIf="gamificationIndex && showGamification" style="border: 0" src="assets/icons/lemons-levels/icon-lemon-level-{{gamificationIndex}}.svg" alt="icon-gamification" />
                  </div>
                  <mat-progress-spinner #spinnerlevel color="accent" mode="determinate" [value]="gamificationPercentFill" [diameter]="spinnerDiameter">
                  </mat-progress-spinner>
                </div>
                <div class="label-level-name" [ngClass]="{'vip-label': userRole}" i18n>{{playerLoyalityName}}</div>
              </div>
            </div>
          
  
            <!-- <ng-template #showAvatar>
              <div class="thumbnail {{size}}" (click)="redirectToProfile()">
                <div class="spinner-level level {{size}}">
                  <div class="spinner-container" (click)="redirectToProfile()">
                    <div class="spinner-background">
                      <div style="height: 100%;" fxLayoutAlign="center center" fxLayout="row">
                        <div class="spinner-value" *ngIf="showGamification">
                          <img *ngIf="gamificationIndex" style="border: 0" src="assets/icons/lemons-levels/icon-lemon-level-{{gamificationIndex}}.svg" alt="icon-gamification" />
                        </div>
                      </div>
                    </div>
                    <mat-progress-spinner #spinnerlevel color="accent" mode="determinate" [value]="gamificationPercentFill" [diameter]="spinnerDiameter">
                    </mat-progress-spinner>
                  </div>
                  <div class="label" fxLayoutAlign="center center" fxLayout="column">
                    <div class="entry" i18n>level <b>{{gamificationLevel}}</b></div>
                  </div>
                </div>
              </div>
            </ng-template> -->
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="size !== 'desktop-top' && size !== 'mobile-top'">
        <div class="thumbnail-container" [ngClass]="{'thumbnail-container-wrapper':cashBackTwoActive}">
          <div class="spinner-level level {{size}}" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="4px" (click)="redirectToProfile()">
            <div class="spinner-container">
              <div class="spinner-background">
                <div style="height: 100%;" fxLayoutAlign="center center" fxLayout="row">
                  <div class="spinner-value">
                    <img *ngIf="isPrizeBoxActive" src="assets/client-area/icon-box-on-80x80.png" alt="icon-gamification" />
                    <img *ngIf="!isPrizeBoxActive" src="assets/client-area/icon-box-off-80x80.png" alt="icon-gamification" />
                  </div>
                </div>
              </div>
              <mat-progress-spinner #spinnerlevel color="accent" mode="determinate" [value]="gamificationPercentFill" [diameter]="spinnerDiameter">
              </mat-progress-spinner>
            </div>
            <div class="label" fxLayoutAlign="center center" fxLayout="column">
              <div class="entry" style="color: #FFFFFF;" i18n>Treasure Boxes</div>
              <div class="label-title" *ngIf="gamificationPercentFill < 100 else PercentFill" i18n>level <b>{{gamificationLevel}} </b>({{gamificationPercentFill.toFixed(2)}}%)</div>
              <ng-template #PercentFill>
                <div class="label-title" i18n>level <b>{{gamificationLevel}} </b>({{gamificationPercentFill.toFixed(0)}}%)</div>
              </ng-template>
            </div>
          </div>
          <div class="spinner-level chargeback {{size}}" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="4px" (click)="redirectToProfile()">
            <div class="spinner-container">
              <div class="spinner-background">
                <div style="height: 100%;" fxLayoutAlign="center center" fxLayout="row">
                  <div class="spinner-value">
                    <img *ngIf="!userChargebackToClaim" src="assets/client-area/icon-cashback-off-80x80.png" alt="icon-gamification" />
                    <img *ngIf="!!userChargebackToClaim" src="assets/client-area/icon-cashback-on-80x80.png" alt="icon-gamification" />
                  </div>
                </div>
              </div>
              <mat-progress-spinner #spinnerchargeback color="accent" mode="determinate" [value]="chargebackPercentFill" [diameter]="spinnerDiameter">
              </mat-progress-spinner>
            </div>
            <div class="label" fxLayoutAlign="center center" fxLayout="column">
              <div class="entry" i18n>cashback 1</div>
              <div class="label-title">
                {{userChargeback?.value.toFixed(2)}} {{userChargeback?.currency}}
              </div>
            </div>
          </div>
          <div class="spinner-level chargeback {{size}}" *ngIf="cashBackTwoActive" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="4px" (click)="redirectToProfile()">
            <div class="spinner-container">
              <div class="spinner-background">
                <div style="height: 100%;" fxLayoutAlign="center center" fxLayout="row">
                  <div class="spinner-value">
                    <img *ngIf="!userChargebackToClaim && showGamification" src="assets/client-area/icon-cashback-2-off.png" alt="icon-gamification" />
                    <img *ngIf="!!userChargebackToClaim && showGamification" src="assets/client-area/icon-cashback-2-on.png" alt="icon-gamification" />
                  </div>
                </div>
              </div>
              <mat-progress-spinner #spinnerchargeback color="accent" mode="determinate" [value]="chargebackPercentFill" [diameter]="spinnerDiameter">
              </mat-progress-spinner>
            </div>
            <div class="label" fxLayoutAlign="center center" fxLayout="column">
              <div class="entry" i18n>cashback 2</div>
              <div class="label-title">
                {{userChargeback?.value.toFixed(2)}} {{userChargeback?.currency}}
              </div>
            </div>
          </div>
          <div class="spinner-level Lottery {{size}}" [ngClass]="{'active-Lottery': userBonusLotteryResponse}" [ngStyle]="{'grid-area': cashBackTwoActive ? '1/2' : 'auto'}" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="4px" (click)="redirectToProfile()">
            <div class="icon-container">
              <img *ngIf="userBonusLotteryResponse" src="assets/client-area/icon-wheel-on.png" />
              <img *ngIf="!userBonusLotteryResponse" src="assets/client-area/icon-wheel-ff.png" />
            </div>
            <div class="label" fxLayoutAlign="center center" fxLayout="column">
              <div class="entry" [ngStyle]="{'color': cashBackTwoActive ? '#FFFFFF' : '#DADADA'}" i18n>Lemon Lottery</div>
              <div *ngIf="userBonusLotteryResponse" class="label-title" i18n>ACTIVE</div>
              <div *ngIf="!userBonusLotteryResponse" class="label-title" style="color: #B8B8B8;" i18n>INACTIVE</div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
