<div class="_swiper-container" #swiperContainer
  (mousedown)="onMouseDown($event)" 
  (mousemove)="onMouseMove($event)" 
  (mouseup)="onMouseUp()"
  (mouseleave)="onMouseLeave()" >

  <div class="_swiper-wrapper" #swiperWrapper>
    <ng-content></ng-content>
  </div>
  
</div>
