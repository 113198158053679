import { Apollo, gql } from 'apollo-angular';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { BaseFirebaseService } from '../http/base-firebase.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { HOST_NAME, LICENCE } from 'src/app/app-routing.module';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { InAppPageParams } from 'src/app/apollo/models/base-models';
import { MISSIONTYPE, Mission, PageDisplayMission } from './missions.models';
import { LoggedStatus } from '../auth/login/login.models';
import { LoginStatusService } from '../auth/login/login-status.service';
import { UserCampaignService } from "../campaigns/campaign.service";
// import { tag } from 'rxjs-spy/cjs/operators';

const pageMissionDisplayQuery = gql`query PageMissionDisplay($locale: Locale!, $licenseName: Licence!){
  missionPageDisplays{
    startDate
    endDate
    missionType
    minBetCurrencies
    fixedCurrency
    minBet
    ref
    mission(locales: [$locale, en]){
      id
      name
      isPrizeEngineEligiblePlayers
      prize{
        name
      }
      missionsAchievements{
        achievementType
        value
      }
      games{
        name
        gameid
        systemName
        thumbnail{
          url(transformation: {
              image: { resize: { width: 206, height: 206, fit: clip } }

            })
        }
        thumbnailDesktopSquare{
          url(transformation: {
            image: { resize: { width: 206, height: 206, fit: clip } }
            document: { output: { format: webp } }
          })
        }
        thumbnailDesktopRectangular{
          url(transformation: {
            image: { resize: { width: 236, height: 177, fit: clip } }
            document: { output: { format: webp } }
          })
        }

      }
      thumbnailMobile{
          url(transformation: {
              image: { resize: { width: 394, height: 128, fit: clip } }

            })
        }
      thumbnailDesktop{
        url(transformation: {
            image: { resize: { width: 405, height: 233, fit: clip } }

          })
      }
      largeDesktopThumbnail{
        url(transformation: {
            image: { resize: { width: 826, height: 233, fit: clip } }

          })
      }
      largeDesktopBanner: largeDesktopThumbnail{
        url(transformation: {
          image: {resize: {width:1248, height: 368, fit:clip}}
        })
      }
      seo {
          metaTitle
          metaDescription
          relCanonical
      }
    }
  }
  inAppPagesParams(where: {AND: {siteName:"missions-all", OR: [{licence_contains_some: [all,$licenseName]}] }}){
    seo(locales: [$locale, en]) {
          metaTitle
          metaDescription
          relCanonical
      }
  }
}
`
const localStorageKeyPostfix = '_mission_tabIndex';

@Injectable({
  providedIn: 'root'
})
export class MissionService extends BaseFirebaseService {

  constructor(
    http: HttpClient,
    afAuth: AngularFireAuth,
    afs: AngularFirestore,
    private apollo: Apollo,
    private loginStatus: LoginStatusService,
    private campaignService: UserCampaignService,
    @Inject(HOST_NAME) public hostName,
    @Inject(LOCALE_ID) public locale: string,
    @Inject(LICENCE) public licence: string,
  ) {
    super(http, afAuth, afs, hostName);
  }

  private _tournaments = new BehaviorSubject<Mission[]>([]);
  private _inAppTournamentMetaData = new BehaviorSubject<InAppPageParams>(null);
  private privateTournaments: Mission[] = [];
  private isStoreInitiated = false;


  public updateMissionsStore(): Observable<Mission[]> {
    this.isStoreInitiated = true;
    return this.loginStatus.getLoginStatus(false).pipe(
      switchMap((user) => {
        return this.apollo
          .watchQuery<{
            missionPageDisplays: PageDisplayMission[],
            inAppPagesParams: InAppPageParams[]
          }>({
            query: pageMissionDisplayQuery,
            variables: {
              locale: this.locale,
              licenseName: this.licence,
              // timestamp: new Date().getTime().toString()
            }
          })
          .valueChanges.pipe(
            tap(item => {
              const inAppparams = item.data.inAppPagesParams?.filter(item => !!item.licence?.find(licence => licence === this.licence))[0] || item.data.inAppPagesParams[0]
              this._inAppTournamentMetaData.next(inAppparams)
            }),
            map(item => item.data.missionPageDisplays),
            map(items => {
              const result: Mission[] = [];
              this.privateTournaments = [];
              for (let missionPageDisplays of items) {
                if (!missionPageDisplays.mission) continue;
                const mission: Mission = { ...missionPageDisplays.mission, ...missionPageDisplays, cmsId: missionPageDisplays.mission.id, missionType: MISSIONTYPE[missionPageDisplays.missionType] };
                result.push(mission);

              }
              result.sort((a, b) => {
                if (a.missionType === MISSIONTYPE.ACTIVE && b.missionType === MISSIONTYPE.UPCOMMING) return -1;
                else if (a.missionType === MISSIONTYPE.UPCOMMING && b.missionType === MISSIONTYPE.UPCOMMING) return 0;
                else if (a.missionType === MISSIONTYPE.ACTIVE && b.missionType === MISSIONTYPE.ACTIVE) return 0;
                else if (a.missionType === MISSIONTYPE.UPCOMMING && b.missionType === MISSIONTYPE.ACTIVE) return 1;
                else if (b.missionType === MISSIONTYPE.HISTORY) return -1;
              });
              return result;
            }),
            switchMap((missions) => {
              // if user is not logged in or not any mission has isPrizeEngineEligiblePlayers true, then no need to check eligibility
              if ((user.isLogged !== LoggedStatus.logged) || (
                missions.filter(mission => mission.isPrizeEngineEligiblePlayers).length === 0)) {
                return of(missions);
              }
              return this.campaignService.isUserEligibleCampaignMissions(missions.map(mission => mission.ref)).pipe(
                map((eligibilityList) => {
                  const eligibilityMap = new Map(eligibilityList);
                  return missions.filter((mission) => (
                    eligibilityMap.get(mission.ref) && mission.isPrizeEngineEligiblePlayers) || !mission.isPrizeEngineEligiblePlayers);
                })
              );
            }),
            tap(result => {
              this._tournaments.next(result);
            })
            // tap(item => console.log(item)),
          )
      })
    )

  }

  public getMissions() {
    return this.isStoreInitiated ? this._tournaments.asObservable() : this.updateMissionsStore();
  }

  public getInAppTournamentMetaData() {
    return this._inAppTournamentMetaData.asObservable().pipe(
      filter(item => !!item)
    );
  }


  public forceUpdateStore() {
    this.updateMissionsStore().pipe(take(1)).subscribe(() => { });
  }

  public getActiveMissionForGameId(gameId: string): Observable<{ missionIndes: number, missionRef: string, mission: Mission }> {
    let index = -1;
    let ref = "";
    let mission = null;
    return this.getMissions().pipe(
      map(missions => {
        missions.filter(item => item.missionType === MISSIONTYPE.ACTIVE).forEach((item, itemIndex) => {
          if (item.games?.filter(game => game.gameid === gameId).length > 0) {
            index = itemIndex;
            ref = item.ref;
          }
        })
        return { missionIndes: index, missionRef: ref, mission: missions[index] };
      })
    )
  }


  public getMission(index: any) {
    return this.getMissions().pipe(
      map(tournaments => {
        return tournaments.find(item => item.cmsId === index);
        // return tournaments.length > index ? tournaments[index] : null
      })
    )
  }


  public saveMissionTab(missionId: string, index: number) {
    localStorage.setItem(this.getLocalStorageKeyForTab(missionId), index.toString());
  }

  public getMissionTab(missionId: string) {
    const index = localStorage.getItem(this.getLocalStorageKeyForTab(missionId));
    return index ? parseInt(index) : 0;
  }

  private getLocalStorageKeyForTab(missionId: string) {
    return missionId + localStorageKeyPostfix;
  }
}
