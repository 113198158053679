<div *ngIf="item" class="reactivation-card" [ngClass]="(detectDeviceService.isDesktop()) ? 'desktop': 'mobile'">
    <div *ngIf="index === currentRecursivePointer - 1 || currentRecursivePointer > index + 1;else prev" class="card-wrap" [class.active]="index === currentRecursivePointer - 1" [class.disabled]="currentRecursivePointer > index + 1">
        <a>
            <div class="card-img">
                <div class="badge-container batch-bg" [class.activeBatch]="index === currentRecursivePointer - 1">
                    <span i18n>Day {{index + 1}}</span>
                </div>
                <img (click)="infoRoute(index)" *ngIf="index === currentRecursivePointer - 1" class="info-icon" src="assets/reactivation/reactivation-info-icon.svg">
                <img [src]="item.value.mobileThumbnail.url"  >
            </div>
            <div class="card-info">
                <h5 i18n>{{item.value.displayName | removehtml}}</h5>
                <p i18n>{{item.value.description | removehtml}}</p>
                <div class="cart-info-btn">
                    <button [disabled]="currentRecursivePointer > index + 1" round-button filled="filled" [routerLink]="['/',item.value.ctaLink]" color="primary" i18n>{{item.value.cta}}</button>
                </div>
            </div>
        </a>
    </div>
    <ng-template #prev>
        <div class="card-prev-wrap">
            <a>
                <div class="card-upcoming-days">
                    <div class="badge-container batch-bg" [class.activeBatch]="index === currentRecursivePointer - 1">
                        <span i18n>Day {{index + 1}}</span>
                    </div>
                </div>
                <div class="card-upcoming-img">
                    <img src="assets/reactivation/upcoming-days-img.svg" alt="upcoming days">
                </div>
            </a>
        </div>
    </ng-template>
</div>