import { Component, ElementRef, Inject, Input, LOCALE_ID, NgZone, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { missionsTrx } from 'src/app/router-translation.labels';
import { DetectDeviceService } from 'src/app/services/utils/detect-device.service';
import { BasePageComponent } from '../../base-page/base-page.component';
import { MISSIONTYPE, Mission, MissionAchievement } from 'src/app/services/missions/missions.models';
import { MissionService } from 'src/app/services/missions/mission.service';
import { MissionDetailsService } from 'src/app/services/missions/mission-details.service';
import { LoginStatusService } from 'src/app/services/auth/login/login-status.service';
import { LoggedStatus } from 'src/app/services/auth/login/login.models';
import { environment } from 'src/environments/environment';

interface TournamentEndCountDown {
  days: number,
  hours: number,
  minutes: number,
  seconds: number
}

@Component({
  selector: 'app-mission-info-box',
  templateUrl: './mission-info-box.component.html',
  styleUrls: ['./mission-info-box.component.scss']
})
export class MissionInfoBoxComponent extends BasePageComponent {
  isCompleted: boolean;
  currency: string;
  numberPrizes: number = 25;
  endDate: string;

  constructor(
    @Inject(LOCALE_ID) public locale: string,
    public router: Router,
    public missionDetailsServices: MissionDetailsService,
    public missionService: MissionService,
    public detectDeviceService: DetectDeviceService,
    protected missionDetailsService: MissionDetailsService,
    protected loginStatusService: LoginStatusService,
    protected _ngZone: NgZone,
    // private cloneService: ClonerService,
  ) { super() }

  protected _mission: Mission;
  public countDown: TournamentEndCountDown;
  public isFinished: boolean;
  tournamentStartDateLocal: Date;
  tournamentEndDateLocal: Date;
  achievements: MissionAchievement[];
  minBetCurrency: string;
  minBet: number;
  isUpcommingRefreshed: boolean = true;
  now: Date;
  game: string;
  @ViewChild('tournamentstartdate') tournamentStartDateElement: ElementRef;


  @Input() tournamentIndex: number;
  @Input() isLogged: boolean = false;
  @Input() isModalDisplay: boolean = false;

  @Input() set mission(tournamentInput: Mission) {
    this._mission = tournamentInput;
    this.tournamentStartDateLocal = new Date(tournamentInput.startDate);
    this.tournamentEndDateLocal = new Date(tournamentInput.endDate);
    this.isUpcommingRefreshed = false;

    if (tournamentInput.games.length > 1) {
      let index = 0;
      this._ngZone.runOutsideAngular(() => {
        timer(0, 2000).pipe(takeUntil(this.unsubscribe)).subscribe(resp => {
          this.game = tournamentInput?.games[index]?.name;
          index = (index + 1) % tournamentInput.games.length;
        })
      });

    }
    else {
      this.game = tournamentInput?.games[0]?.name
    }

    if (tournamentInput.missionType === MISSIONTYPE.ACTIVE) {
      this.countDown = {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
      }
      this._ngZone.runOutsideAngular(() => {
        const source = timer(0, 1000).pipe(takeUntil(this.unsubscribe)).subscribe(resp => {
          this.now = new Date(Date.now());
          const diff = Math.max(0, Math.floor(Math.floor((new Date(this._mission.endDate).getTime() - new Date().getTime()) / 1000)));

          let seconds = diff;
          let minutes = Math.floor(seconds / 60);
          let hours = Math.floor(minutes / 60);
          let days = Math.floor(hours / 24);

          hours = hours - (days * 24);
          minutes = minutes - (days * 24 * 60) - (hours * 60);
          seconds = seconds - (days * 24 * 60 * 60) - (hours * 60 * 60) - (minutes * 60);

          this.countDown.days = days;
          this.countDown.hours = hours;
          this.countDown.minutes = minutes;
          this.countDown.seconds = seconds;
          if (this.tournamentStartDateElement) {
            if (this.countDown.days > 0) {
              this.tournamentStartDateElement.nativeElement.innerHTML = `${this.countDown.days}d ${this.countDown.hours}h ${this.countDown.minutes}m`;
            }
            else {
              this.tournamentStartDateElement.nativeElement.innerHTML = `${this.countDown.hours}h ${this.countDown.minutes}m ${this.countDown.seconds}s`;

            }
          }
        })
      });
    }
    else if (tournamentInput.missionType === MISSIONTYPE.UPCOMMING) {
      this._ngZone.runOutsideAngular(() => {
        const source = timer(0, 5000).pipe(takeUntil(this.unsubscribe)).subscribe(resp => {
          this.now = new Date(Date.now());
          this.now.setSeconds(this.now.getSeconds() - 30)
          if (this.now >= this.tournamentStartDateLocal && !this.isUpcommingRefreshed) {
            this.isUpcommingRefreshed = true;
            this.missionService.forceUpdateStore();
          }
        })
      });
    }
  };

  get mission() {
    return this._mission
  }

  getDetails() {
    this.loginStatusService.getLoginStatus().pipe(takeUntil(this.unsubscribe)).subscribe(status => {
      if (status.isLogged === LoggedStatus.logged) {
        this.isLogged = true;
        this.currency = status.currency;
        const minBetCurrencies = this.mission.minBetCurrencies;
        if (minBetCurrencies && minBetCurrencies[this.currency.toLowerCase()]) {
          this.minBet = minBetCurrencies[this.currency.toLowerCase()];
          this.minBetCurrency = status.currency;
        }
        else {
          this.minBet = this.mission.minBet;
          this.minBetCurrency = environment.baseCurrency;
        }
        // this.missionDetailsService.getMissionAchievements(this._mission, this._mission.cmsId, this.minBetCurrency).subscribe(resp => {
        //   this.achievements = resp.achievements;
        //   console.log('achv', resp);
        //   this.isCompleted = resp.isCompleted;
        // })
      }
      else {
        this.isLogged = false;
        this.minBet = this.mission.minBet;
        this.minBetCurrency = environment.baseCurrency;
      }

    })
    this.numberPrizes = 1;

    this.endDate = `${new Date(this.mission.endDate).toLocaleDateString()} ${new Date(this.mission.endDate).toLocaleTimeString()}`;
  }

  ngOnInit(): void {
    this.isDesktop = this.detectDeviceService.isDesktop();
    if (this.isLogged) {
      this.prepareTournamentData();
    }
  };

  getImageUrl() {
    return `url("${this.mission.thumbnailMobile.url}")`;
  }

  prepareTournamentData() {
    const endDate = new Date(new Date().getTime() + (2 * 60 * 60 * 1000));

  }

  checkAndOpen() {
    this.checkAndOpen && this.router.navigate([missionsTrx, 'id', this.mission.cmsId]);
  }

}
