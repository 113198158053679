import { Injectable } from '@angular/core';
import { BehaviorSubject, filter, Observable, tap } from 'rxjs';
import { NotificationQueueElement, NotificationVariables, UserNotificationTemplateName } from './notifications.models';

@Injectable({
  providedIn: 'root'
})
export class UserNotificationService {

  private messagePublisher: BehaviorSubject<NotificationQueueElement> = new BehaviorSubject<NotificationQueueElement>(null);


  constructor() {
  }

  public notify(templateName: UserNotificationTemplateName, notificationVariables: NotificationVariables = null) {
    this.messagePublisher.next({ "templateName": templateName, "notificationVariables": notificationVariables });
  }

  public getMessages(): Observable<NotificationQueueElement> {
    return this.messagePublisher.asObservable().pipe(
      filter(item => !!item),
      tap(() => {
        this.messagePublisher.next(null);
      })
    );
  }
}
