import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { MinimizeSiteService } from '../services/utils/hide-footer.service';

@Injectable()
export class MaximumPageResolver implements Resolve<boolean> {
  constructor(
    public minimizeSiteService: MinimizeSiteService,
  ) { }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any>|Promise<any>|any {
    return this.minimizeSiteService.restorePage();
  }
}
