import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-common-page-title',
  templateUrl: './common-page-title.component.html',
  styleUrls: ['./common-page-title.component.scss']
})
export class CommonPageTitleComponent implements OnInit {
  
  @Input() icon: any;

  ngOnInit(): void {
  }
}
