export const MIX_DIALOG_CONFIG = {
    maxWidth: '90vw',
    maxHeight: '80vh',
    height: '100%',
    width: '100%',

}

export const FULL_DIALOG_CONFIG = {
    maxWidth: '100vw',
    maxHeight: '100vh',
    height: '100%',
    width: '100%',
}

export const SMALL_DIALOG_CONFIG = {
    maxWidth: '90vw',
    maxHeight: '50vh',
    height: '100%',
    width: '100%',
}

export const CONFIRM_DIALOG_CONFIG = {
    maxWidth: '50vw',
    maxHeight: '60vh',
    height: '100%',
    width: '100%',
}

export const DESKTOP_REGULAR = {
    width: '500px',
    height: '80vh',
}

export const DESKTOP_FULL_HEIGHT = {
    width: '900px',
    height: '98vh',
}

export const DESKTOP_INFO = {
    width: '500px',
}

export const DESKTOP_SMALL = {
    width: '400px',
}

export const DESKTOP_LOGIN = {
    height: '636px',
    width: '520px',
}

export const DESKTOP_SIGNUP = {
    height: '634px',
    width: '590px',
}

export const TEST_SUCCESS_DIALOG_CONFIG = {
    maxWidth: '646px',
    width: '100%'
    //maxHeight: '',
    //height: ',
    // width: '94%',
}

export const MOBILE_EDIT_PROFILE_AVATAR ={
    maxWidth:'940px',
    maxHeight:'auto',
    width: '100%',         
    height: '100%', 
}

export interface DialogConfig {
    maxWidth?: string,
    maxHeight?: string,
    height?: string,
    width?: string,
}