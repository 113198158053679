import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { CURRENT_BALANCE } from '../http/base-http.service';
import { CookieService } from 'ngx-cookie-service';
import { LoggedStatus } from '../auth/login/login.models';
import { LoginStatusService } from '../auth/login/login-status.service';
import { BalanceInAppInfo, BalanceResponse } from './wallet.models';
import { BalanceStatusService } from './balance-status.service';
import { BaseWalletService } from '../http/base-wallet.service';
import { HOST_NAME } from 'src/app/app-routing.module';


@Injectable({
  providedIn: 'root'
})
export class BalanceService extends BaseWalletService {


  constructor(http: HttpClient, cookie: CookieService, private balanceStatusInfoService: BalanceStatusService, private loginStatusService: LoginStatusService, @Inject(LOCALE_ID) public locale: string, @Inject(HOST_NAME) public hostName) {
    super(http, cookie, locale, hostName);
  }

  public requestBalance(): Observable<BalanceInAppInfo> {
    return this.get<BalanceResponse>(CURRENT_BALANCE).pipe(
      switchMap((resp) => {
        this.balanceStatusInfoService.updateBalanceInfo({
          balance: parseFloat(resp.balance),
          currency: resp.currency,
          bonus_balance: resp.bonus_balance,
          non_sticky_bonus: resp.non_sticky_bonus || false
        });
        return this.balanceStatusInfoService.getBalanceInfoStatus();
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 403) {
          this.loginStatusService.updateUserInfo({
            isLogged: LoggedStatus.notLogged,
          });
        }
        // return an observable with a user-facing error message
        return this.balanceStatusInfoService.getBalanceInfoStatus();
      })
    );
  }
}
