import { Apollo, gql } from 'apollo-angular';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { AngularFirestore, CollectionReference, DocumentData, Query } from '@angular/fire/compat/firestore';
import { LoginStatusService } from '../auth/login/login-status.service';
import { delay, filter, flatMap, map, mergeMap, share, switchMap, take, tap } from 'rxjs/operators';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { LoggedStatus } from '../auth/login/login.models';
import { Notification, NotificationDB, Notificationinfo, NotificationTemplate } from './notifications.models';


const query = gql`query Notification($locale: Locale!,$names:[String!]){
  notifications(locales: [$locale, en],where: {name_in: $names}) {
      name
      titleText
      thumbnail {
        url
      }
  }
}
`

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  //, ref => ref.where('isSeen', '==', 'false')
  constructor(private db: AngularFirestore, private apollo: Apollo, private loginStatusService: LoginStatusService, @Inject(LOCALE_ID) public locale: string) { }

  private newMessages: Subject<Notificationinfo> = new Subject<Notificationinfo>();

  private fillTemplate(templateString, templateVars) {
    return new Function("return `" + templateString + "`;").call(templateVars);
  }

  public getNewMessages(): Observable<Notificationinfo> {
    return this.newMessages.asObservable();
  }


  fetchNotifications(): Observable<Notificationinfo> {
    return this.loginStatusService.getUserState().pipe(
      filter(user => user !== undefined && !!user.dbUser),
      switchMap((user) => {
        return this.loginStatusService.getLoginStatus();
      }),
      switchMap((user) => {

        return this.db.collection('prize-users').doc(user.username).collection<NotificationDB>('notifications', ref => {
          let query: CollectionReference | Query<DocumentData> = ref;
          query = query.where("isSeen", "==", false).orderBy('createdAt', "desc")
          return query
        }).valueChanges()
      }),
      mergeMap((notificationDB: NotificationDB[]) => {
        const names = new Set(notificationDB.map(value => value.messageId))
        if (names.size > 0) {
          return combineLatest(of(notificationDB), this.apollo.watchQuery<{ notifications: NotificationTemplate[] }>({
            query: query,
            variables: {
              names: [...names],
              locale: this.locale
            }
          }).valueChanges).pipe(
            delay(0),
            share(),
            map(([notificationDB, notificationsCMS]) => {
              const notifications: Notification[] = notificationDB.map((itemDB) => {
                const itemCMS = notificationsCMS.data.notifications.find(i => i.name == itemDB.messageId);
                if (itemCMS === undefined) return null;
                const notification: Notification = {
                  text: this.fillTemplate(itemCMS.titleText, itemDB.params),
                  createdAt: itemDB.createdAt,
                  thumbnail: itemCMS.thumbnail,
                  isSeen: itemDB.isSeen
                }
                return notification;
              })
              const notificationInfo: Notificationinfo = {
                notifications: notifications,
                numberTotal: notifications.length,
                numberNotSeen: notifications.filter(i => !i?.isSeen).length
              }
              return notificationInfo
            }),
            tap(resp => this.newMessages.next(resp))
          )
        }
        else {
          return of(null)
        }

      }
      ),

    )

  }

  setNotificationSeen() {
    return this.loginStatusService.getLoginStatus().pipe(
      filter(user => user !== undefined && user.isLogged === LoggedStatus.logged),
      switchMap((user) => {
        return this.db.collection('prize-users').doc(user.username).collection('notifications', ref => {
          let query: CollectionReference | Query<DocumentData> = ref;
          query = query.where("isSeen", "==", false)
          return query
        }).snapshotChanges()
      }),
      take(1),
      flatMap(clients => clients),
    ).subscribe(doc => {
      const docRef = doc.payload.doc.ref;
      docRef.delete();
    })
  }
}
