import { gql } from 'apollo-angular';


//            document: { output: { format: webp } }

export const GAME_FIELDS_MOBILE = `
        name
        id
        gameid
        systemName
        hideGame
        tag
        initialLoadColor {
          css
        }
        thumbnail {
          url(transformation: {
            image: { resize: { width: 206, height: 206, fit: clip } }
            document: { output: { format: webp } }
          })
        }
        thumbnailDesktopSquare{
          url(transformation: {
            image: { resize: { width: 206, height: 206, fit: clip } }
            document: { output: { format: webp } }
          })
        }
        thumbnailDesktopRectangular{
          url(transformation: {
            image: { resize: { width: 236, height: 177, fit: clip } }
            document: { output: { format: webp } }
          })
        }
        gameProvider{
          name
          providerExclusionTerritories{
            countryCode
            state
          }
        }
        animation{
          url
          fileName
        }
        `;

const GAME_FIELDS_DESKTOP = `
        name
        id
        gameid
        systemName
        hideGame
        tag
        initialLoadColor {
          css
        }
        thumbnailDesktop{
          url(transformation: {
            image: { resize: { width: 200, height: 200, fit: clip } }
            document: { output: { format: webp } }
          })
        }
        gameProvider{
          name
          providerExclusionTerritories{
            countryCode
            state
          }
        }
        thumbnailDesktopSquare{
          url(transformation: {
            image: { resize: { width: 194, height: 194, fit: clip } }
            document: { output: { format: webp } }
          })
        }
        thumbnailDesktopRectangular{
          url(transformation: {
            image: { resize: { width: 236, height: 177, fit: clip } }
            document: { output: { format: webp } }
          })
        }
        animation{
          url
          fileName
        }
        `;

export const FRONT_PAGE_GAMES_QUERY = gql`query FrontPageGames($locale: Locale!,$licenseName: Licence, $countryName: [Countries!]){
  pagesCategories(where: {AND: {regionName: front, country_contains_some:$countryName }},locales: [$locale, en]) {
    seo{
      metaTitle
      metaDescription
    }
    categories {
      name,
      id,
      gameType,
      displayName,
      url,
      numberGamesFrontPageMobile,
      numberGameRows,
      icon{
        url
      },
      thumbnail{
        url
      },
      game(where: {  OR: [{ licence: $licenseName }, { licence: all }] }, first: 50) {
        ${GAME_FIELDS_MOBILE}
      }
    }
  }
  operators: pagesCategories(locales: [$locale, en],where: {AND: {regionName: front_operators, country_contains_some:$countryName }} ) {
    categories {
      name,
      displayName,
      gameType,
      thumbnailMobile{
        url(transformation: {
          image: { resize: { width: 300, height: 414, fit: clip } }
            document: { output: { format: webp } }
        })
      }
    }
  }
  }
  `;

export const FRONT_PAGE_GAMES_DESKTOP_QUERY = gql`query FrontPageGamesDestkop($locale: Locale!,$licenseName: Licence, $countryName: [Countries!]){
  pagesCategories(where: {AND: {regionName: front, country_contains_some:$countryName }},locales: [$locale, en]) {
    seo{
      metaTitle
      metaDescription
    }
    categories {
      id
      name,
      gameType,
      displayName,
      numberGamesFrontPageMobile,
      numberGameRowsDesktop,
      url,
      displayType,
       icon{
        url
      }
      thumbnail{
        url
      },
      game(where: {  OR: [{ licence: $licenseName }, { licence: all }] }, first: 50) {
        ${GAME_FIELDS_DESKTOP}
      }
    }
  }
  operators: pagesCategories(locales: [$locale, en],where: {AND: {regionName: front_operators, country_contains_some:$countryName }}) {
    categories {
      name,
      displayName,
      gameType,
      thumbnailDesktop{
        url(transformation: {
          image: { resize: { width: 235, height: 153, fit: clip } }
          document: { output: { format: webp } }
        })
      }
    }
  }
  }
  `;

export const FRONT_PAGE_GAME_DESCRIPTION = gql`query FrontPageGamesDescription($gameName:String!,$locale: Locale!){
      game(locales: [$locale, en],where: {systemName: $gameName}) {
        name
        gameid
        provider
        systemName
        tag
        seo {
          metaTitle
          metaDescription
          relCanonical
        }
        thumbnail {
          url
        }
        thumbnailDesktopSquare{
          url
        }
        shortDescription {
          html
        }
        animation{
          url
          fileName
        }
      }
  }
  `;

export const FRONT_PAGE_TOP_BANNER = gql`
  query FrontPageTopBanner($locale: Locale!,$language: String!, $width: Int! = 414){
    banner(locales: [$locale, en],where: {name: "front-top"}) {
      bannerItems {
        name
        image {
          url(transformation: {
            document: { output: { format: webp } },
            image: { resize: { width: 412, height: 200} }
          })
        }
        bannerLocalizedImages(where: {language:$language}){
          image{
            url(transformation: {
              image: { resize: { width: 637, height: 255, fit: clip } }
            })
          }
          desktopImage{
            url(transformation: {
              document: { output: { format: webp } },
              image: { resize: { width: 940, height: 358} }
            })
          }
        }
        buttonLabel
        loggedButtonLabel
        buttonLink
        loggedButtonLink
        desktopNewImage{
          url(transformation: {
              document: { output: { format: webp } },
              image: { resize: { width: 1248, height: 344} }
            })
        }
        originalDesktopNewImage: desktopNewImage{
          url
        }
        mobileNewImage{
          url(transformation: {
              document: { output: { format: webp } },
              image: { resize: { width: $width, fit: scale} }
              validateOptions: true
            })
        }
        mobileVideo{
          url
        }
        originalMobileNewImage: mobileNewImage{
          url
        }
        description: mobileDescription
        titleText: mobileTitleText
        forceOriginalImage
        hideForLogged
        hideForNotLogged
      }
    }
    }
  `;

export const FRONT_PAGE_TOP_BANNER_DESKTOP = gql`query FrontPageTopBanner($locale: Locale!,$language: String!){
  banner(locales: [$locale, en],where: {name: "front-top"}) {
    bannerItems {
      name
      image {
        url(transformation: {
          document: { output: { format: jpg } },
          image: { resize: { width: 940, height: 358} }
        })
      }
      leftBackground {
        url(transformation: {
            document: { output: { format: webp } }
          })
      }
      rightBackground {
        url(transformation: {
            document: { output: { format: webp } }
          })
      }
      desktopImage{
        url
      }
      bannerLocalizedImages(where: {language:$language}){
        image{
          url(transformation: {
            image: { resize: { width: 637, height: 255, fit: clip } }
          })
        }
        desktopImage{
          url(transformation: {
            document: { output: { format: webp } }
          })
        }
      }
      desktopNewImage{
        url(transformation: {
            document: { output: { format: webp } },
            image: { resize: { width: 1248, height: 344} }
          })
      }
      buttonLabel
      loggedButtonLabel
      buttonLink
      loggedButtonLink
      description
      titleText
      forceOriginalImage
      hideForLogged
      hideForNotLogged
    }
  }
  }
  `;

export const FRONT_PAGE_EXPANSION_PANEL = gql`query FrontPageExpansionPanel($locale: Locale!,$licenseName:Licence!){
  expansionPanels(locales: [$locale, en],where:{ AND: {name: "front"},  OR:  [{ licence: $licenseName }, { licence: all }] } ) {
    licence
    item{
      title
      content{
        html
      }
    }
  }
  }
  `;
