<ng-container [ngSwitch]="isEditNickname">
    <div class="edit-nickname" [ngClass]="isDesktop===true?'desktop':'mobile'" *ngSwitchCase="true">
        <div class="nickname-form-field form-custom-design" [ngClass]=" {'dark-background': isDarkBackground}">
            <form>
                <mat-form-field [formGroup]="editNicknameForm" appearance="outline">
                    <mat-label i18n>Enter Your Nick</mat-label>
                    <input matInput type="text" formControlName="nickname" autofocus type="text" (input)="editNicknameForm.get('nickname').markAsTouched()">
                    <img matSuffix src="assets/icons/icon-edit-no-outline-yellow.svg">
                    <mat-error *ngIf="!editNicknameForm.get('nickname')?.errors?.serverError && editNicknameForm.get('nickname').touched && editNicknameForm.get('nickname').errors?.required" i18n>Nickname is required.</mat-error>
                    <mat-error *ngIf="editNicknameForm.get('nickname')?.errors?.prohibitedNickname" i18n>The selected nickname is not allowed.</mat-error>
                    <mat-error *ngIf="editNicknameForm.get('nickname')?.errors?.nicknameLengthExceeded" i18n>The nickname should be no longer than 15 characters.</mat-error>
                    <mat-error *ngIf="editNicknameForm.get('nickname')?.errors?.serverError">
                        {{ editNicknameForm.get('nickname').errors?.serverError }}
                    </mat-error>
                </mat-form-field>
            </form>
            <div class="btn-wrap">
                <button round-button color="warn" class="save-button" filled="filled" (click)="submitNickname()" i18n>OK</button>
            </div>
        </div>
    </div>
    <ng-container *ngSwitchCase="false">
        <div (click)="enableEditNickname(true)" fxLayoutGap="15px" fxLayoutAlign="center center" fxLayout="row" class="nickname-edit-idle">
            <div class="text">{{nickname}}</div>
            <img src="assets/icons/icon-edit.svg" alt="icon-edit">
        </div>
    </ng-container>
</ng-container>
