import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UntypedFormGroup } from '@angular/forms';
import { RedeemCodeResponse } from './client-area.models';
import { REDEEM_PROMOCODE } from '../http/base-http.service';
import { BaseUsermgrService } from '../http/base-usermgr.service';
import { CookieService } from 'ngx-cookie-service';
import { HOST_NAME } from '../../app-routing.module';


@Injectable({
  providedIn: 'root'
})
export class RedeemCodeService extends BaseUsermgrService {

  constructor(http:HttpClient,cookie:CookieService,@Inject(LOCALE_ID) public locale: string, @Inject(HOST_NAME) public hostName) {
    super(http,cookie,locale,hostName);
   }


  requestRedeemCode (redeemCodeForm: UntypedFormGroup): Observable<RedeemCodeResponse> {
    return super.postRequest<RedeemCodeResponse>(REDEEM_PROMOCODE,redeemCodeForm)
  }
}
