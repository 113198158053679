import { Inject, Injectable, LOCALE_ID, PLATFORM_ID } from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { HOST_NAME } from 'src/app/app-routing.module';

// <link rel="alternate" hreflang="en-gb" href = "https://en-gb.example.com/page.html" />
@Injectable({
    providedIn: 'root'
})
export class HeadManipulateService {
    private stylesMap: Map<any, Node> = new Map();
    private host: Node;

    constructor(
        @Inject(DOCUMENT) private doc: Document,
        @Inject(HOST_NAME) private hostname: string,
        @Inject(LOCALE_ID) private locale: string,
        @Inject(PLATFORM_ID) private platform: string,

    ) {
    }

    public createLinkNode(lang: string, href: string): void {
        let link: HTMLLinkElement = this.doc.createElement('link');
        link.setAttribute('rel', 'alternate');
        link.setAttribute('href', href);
        link.setAttribute('hreflang', lang);
        this.doc.head.appendChild(link);
    }

    public createCanonicalNode(href: string): void {
        let link: HTMLLinkElement = this.doc.createElement('link');
        link.setAttribute('rel', 'canonical');
        link.setAttribute('href', `https://${this.hostname}/${this.locale}/${href}`);
        this.doc.head.appendChild(link);
        this.stylesMap.set(href, link);
    }

    removeLinks(): void {
        if (isPlatformBrowser(this.platform)) {
            document.querySelectorAll('[rel="alternate"]').forEach((node) => {
                node.remove();
            })
            document.querySelectorAll('[rel="canonical"]').forEach((node) => {
                node.remove();
            })
        }
    }
}