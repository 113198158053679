import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { BaseUsermgrService } from '../../http/base-usermgr.service';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { LoginStatusService } from '../login/login-status.service';
import { LoggedStatus, LogoutResponse, UserInfo } from '../login/login.models';
import { LOGOUT_URL } from '../../http/base-http.service';
import { Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { HOST_NAME } from 'src/app/app-routing.module';
import { FirebaseAuthService } from '../firebase/firebase-auth.service';

@Injectable({
  providedIn: 'root'
})
export class LogoutService extends BaseUsermgrService {


  constructor(http: HttpClient, cookie: CookieService, private firebaseAuthService: FirebaseAuthService, private loginStatusService: LoginStatusService, @Inject(LOCALE_ID) public locale: string, @Inject(HOST_NAME) public hostName) {
    super(http, cookie, locale, hostName);
  }

  logout = () => {
    this.loginStatusService.updateUserInfo({
      isLogged: LoggedStatus.notLogged,
    });
    return this.loginStatusService.getLoginStatus();
  }


  public requestLogoutUser(): Observable<UserInfo> {
    return this.get<LogoutResponse>(LOGOUT_URL).pipe(
      filter(resp => resp.status.toUpperCase() === "OK"),
      switchMap(() =>
        this.logout()
      ),
    )
  }

  // requestLogoutUser(): Observable<UserInfo> {
  //   return super.get<LogoutResponse>(LOGOUT_URL).pipe(
  //       switchMap((resp) => {
  //         this.loginStatusService.updateUserInfo({
  //           isLogged: resp.status == "logged" ? LoggedStatus.logged : LoggedStatus.notLogged,
  //         });
  //         return this.loginStatusService.getLoginStatus();
  //       })
  //     );
  // }
}
