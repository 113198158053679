import { Apollo } from 'apollo-angular';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';

import { map, share } from 'rxjs/operators';
import { TOP_MENU_ITEMS_QUERY } from '../../apollo/top-menu/top-menu-items';
import { TopMenuResponse } from '../../apollo/models/base-models';
import { LICENCE } from 'src/app/app-routing.module';

@Injectable({
  providedIn: 'root'
})
export class InitialDataService {

  constructor(
    private apollo: Apollo,
    @Inject(LOCALE_ID) public locale: string,
    @Inject(LICENCE) public license: string,
  ) { }


  fetchData() {
    return this.apollo
      .watchQuery<TopMenuResponse>({
        query: TOP_MENU_ITEMS_QUERY,
        variables: {
          locale: this.locale,
          licenseValue: this.license
        }
      })
      .valueChanges.pipe(
        share(),
        map((response) => ({
          topMenuItems: response.data.topMenuLists.find(item => {
            return item.licence === this.license
          })?.menuItem || response.data.topMenuLists[0].menuItem, languages: response.data.languages.filter(item => {
            return item.licence === this.license || item.licence === 'all'
          }),
          footer: response.data.footers[0], maintenance: response.data.maintenance,
          countries: response.data.__type.enumValues.map((item: any) => item.name),
          pageScripts: response.data.pageScripts
        })
        ),
      )
  }

}
