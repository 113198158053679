import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

export const GAME_ACTION_QUIT = "quit"
export const GAME_ACTION_LAUNCH = "launch"


@Injectable({
  providedIn: 'root'
})
export class ComponentComunicationService {

  constructor() { }

  private msg:Subject<string> = new Subject();

  public getMessages(): Observable<string>{
    return this.msg.asObservable();
  }

  public postMessage(msg:string){
    this.msg.next(msg);
  }
}
