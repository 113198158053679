import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { BasePaymentService } from '../../http/base-payment.service';
import { PENDING_WITHDRAWAL } from '../../http/base-http.service';
import { BehaviorSubject, map, Observable, switchMap, take } from 'rxjs';
import { PendingWithdrawalList } from './withdrawal.models';
import { HOST_NAME } from 'src/app/app-routing.module';
import { LoginStatusService } from '../../auth/login/login-status.service';

@Injectable({
  providedIn: 'root'
})
export class PendingWithdrawalService extends BasePaymentService {

  public lastUserMessagesResponse = new BehaviorSubject<PendingWithdrawalList>(null);
  public pendingWithdrawalList$ = new BehaviorSubject<PendingWithdrawalList>(null);
  public currentPage = new BehaviorSubject<number>(1);

  pendingWithdrawalsCount$ = this.pendingWithdrawalList$.asObservable();

  constructor(http: HttpClient, cookie: CookieService,  private loginStatus: LoginStatusService, @Inject(LOCALE_ID) public locale: string, @Inject(HOST_NAME) public hostName) {
    super(http, cookie, locale,hostName);
  }

  private requestUserMessages(page: number): Observable<PendingWithdrawalList> {
    return this.loginStatus.getIfUserLogged().pipe(
      take(1),
      switchMap((status) => {
        return this.get<PendingWithdrawalList>(PENDING_WITHDRAWAL + '?full=1&page=' + page).pipe(
          map(response => {
            this.lastUserMessagesResponse.next(response);
            return response;
          })
        );
      })
    )
  }

  public getList(): Observable<PendingWithdrawalList>{
    return this.loginStatus.getIfUserLogged().pipe(
      take(1),
      switchMap((status) => {
        return this.get<PendingWithdrawalList>(PENDING_WITHDRAWAL).pipe(
          map(response => {
            this.pendingWithdrawalList$.next(response);
            return response;
          })
        );
      })
    )
  }

  public goToNextPage(): void {
    if (this.lastUserMessagesResponse.value && this.lastUserMessagesResponse.value.next) {
      this.currentPage.next(this.currentPage.value + 1);
      this.requestUserMessages(this.currentPage.value).subscribe();
    }
  }

  public goToPreviousPage(): void {
    if (this.currentPage.value > 1) {
      this.currentPage.next(this.currentPage.value - 1);
      this.requestUserMessages(this.currentPage.value).subscribe();
    }
  }
}
