import { Injectable } from '@angular/core';
import { Observable, of, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DialogClosePreviousUrl {

  private _url: string[];
  private _scrollPosition: number;

  constructor() { }

  setUrl(url: string[], scrollPosition?: number) {
    this._url = ["", ...url.filter(item => item != "")];
    this._scrollPosition = scrollPosition;
  }

  getUrl(): Observable<string[]> {
    return (this._url) ? of(this._url).pipe(tap(() => this._url)) : of(null);
  }

  getScrollPosition(): number {
    return this._scrollPosition || 0;
  }

  setScrollPosition(scrollPosition: number) {
    this._scrollPosition = scrollPosition;
  }

  reset(): void {
    this._scrollPosition = 0;
    this._url = [""];
  }

}
