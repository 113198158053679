import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BasePageComponent } from 'src/app/components/base-page/base-page.component';
import { SearchConfigFilterItem } from 'src/app/services/games/search-game.service';

@Component({
  selector: 'app-search-filter-selector',
  templateUrl: './search-filter-selector.component.html',
  styleUrls: ['./search-filter-selector.component.scss']
})
export class SearchFilterSelectorComponent extends BasePageComponent implements OnInit {

  public filterItem: SearchConfigFilterItem;

  @Input() _filterItem: SearchConfigFilterItem;
  @Output() choosedFilter = new EventEmitter<SearchConfigFilterItem>();

  constructor() { 
    super()
  }

  ngOnInit() {
    this.filterItem = this._filterItem;
  }

  onFilterChoose(filterItem: SearchConfigFilterItem) {
    this.choosedFilter.next(filterItem)
  }

}
