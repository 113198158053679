import { AbstractControl, ValidationErrors } from '@angular/forms';

const prohibitedNicknames = ['ty', 'ja', 'te', 'én', 'du', 'ich', 'sie', 'jeg', 'you', 'i'];

export function nicknameValidator(control: AbstractControl): ValidationErrors | null {
  const value = control.value?.trim() || '';
  const isProhibited = prohibitedNicknames.includes(value.toLowerCase());
  const isValidLength = value.length <= 15;

  if (isProhibited) {
    return { prohibitedNickname: true };
  }
  if (!isValidLength) {
    return { nicknameLengthExceeded: true };
  }

  return null;
}
