import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ChargebackService } from 'src/app/services/profile/chargeback.service';
import { GamificationLevelService } from 'src/app/services/profile/gamification-level.service';
import { BasePageComponent } from '../../base-page/base-page.component';
import { filter, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { UserBonusLottery, UserChargeback } from 'src/app/services/profile/profile.models';
import { Subscription } from 'rxjs';
import { LoginStatusService } from 'src/app/services/auth/login/login-status.service';
import { AvatarService } from 'src/app/services/profile/avatar.service';
import { DESKTOP_REGULAR, DialogConfig, MIX_DIALOG_CONFIG } from 'src/app/components/dialog/dialog.config';
import { MatDialog } from '@angular/material/dialog';
import { DetectDeviceService } from 'src/app/services/utils/detect-device.service';
import { Router } from '@angular/router';
import { LoggedStatus } from 'src/app/services/auth/login/login.models';
import { LicenceService } from 'src/app/services/utils/licence.service';
import { BonusLotteryService } from 'src/app/services/profile/bonuslottery.service';
import { PrizeBoxService } from 'src/app/services/client-area/prize-box.service';

const LEVEL_NAMES = ['green', "yellow", "bronze", "silver", "gold", "diamond"];

@Component({
  selector: 'app-profile-info',
  templateUrl: './profile-info.component.html',
  styleUrls: ['./profile-info.component.scss']
})
export class ProfileInfoComponent extends BasePageComponent {

  constructor(
    protected licenceService: LicenceService,
    protected chargebackService: ChargebackService,
    private gamificationLevelService: GamificationLevelService,
    private loginStatusService: LoginStatusService,
    public avatarService: AvatarService,
    private detectDesktopService: DetectDeviceService,
    private bonusLotteryService: BonusLotteryService,
    private prizeBoxService: PrizeBoxService,
    private router: Router,
    public dialog: MatDialog
  ) { super() }

  @Input() size: string;
  @Output() closeEvent = new EventEmitter();
  spinnerDiameter: number = 100;
  userChargeback: UserChargeback;
  userChargebackToClaim: UserChargeback;
  userBonusLotteryResponse: UserBonusLottery;

  chargebackDays2Go: number;
  chargebackPercentFill: number = 0.;
  chargebackSubscription: Subscription;
  cashBackTwoActive: boolean = false;

  gamificationPercentFill: number = 0.;
  gamificationLevel: number = 1;
  gamificationSubscription: Subscription;
  forceDbRefresh: boolean = false;
  showGamification: boolean;
  playerLoyalityName: string;
  isPrizeBoxActive = false;

  username: string;

  avatar: string;
  gamificationIndex: number;
  userRole: boolean;
  verified: string;

  private _visible: boolean = false;
  dialogSize: DialogConfig = MIX_DIALOG_CONFIG;
  @Input() isAllowRedirectToClientArea: boolean = true;


  @Input()
  set visible(isVisible: boolean) {
    this._visible = isVisible;
    // if (isVisible) {
    //   this.subscribeData();
    // }
    // else {
    //   this.unsubscribeData();
    // }
  }

  get visible() {
    return this._visible
  }

  ngOnInit(): void {
    if (this.size === "small") this.spinnerDiameter = 40;
    if (this.size === "desktop-top") {
      this.spinnerDiameter = 32;
    }
    if (this.size === "desktop-top" || this.size === "small") {
      this.forceDbRefresh = true;
    }
    if (this.size === "mobile-top") {
      this.spinnerDiameter = 40;
      this.forceDbRefresh = true;
    }
    this.loginStatusService.getLoginStatus().pipe(
      takeUntil(this.unsubscribe),
      filter(resp => resp.isLogged !== LoggedStatus.voidState)
    ).subscribe(resp => {
      this.username = resp.username;
      let roleList = (resp.fullInfo?.roles as string[]) || [];
      if(roleList)
        roleList = roleList.map(role => role.toLowerCase());
      this.userRole = roleList.some(role=>this.loginStatusService.vipRoles.includes(role));
      this.verified = resp?.fullInfo?.verification_status;
      this.subscribeData();
      this.subscribeToBonusLottery();

      // if (this.visible) {
      // }
    })

    if (this.detectDesktopService.isDesktop()) {
      this.dialogSize = DESKTOP_REGULAR
    }

    this.avatarService.getAvatar().pipe(takeUntil(this.unsubscribe)).subscribe(data => this.avatar = data);
    this.isDesktop = this.detectDesktopService.isDesktop();
  }

  redirectToProfile(): void {
    if (!this.isAllowRedirectToClientArea) return;
    this.closeEvent.next("");
    this.router.navigate(['', 'client-area', 'main']);
  }

  subscribeData() {
    if (this.chargebackSubscription) this.chargebackSubscription.unsubscribe();

    this.userChargebackToClaim = null;
    this.chargebackSubscription = this.chargebackService.getChargeback(false, this.forceDbRefresh).pipe(
      takeUntil(this.unsubscribe),
    )
      .subscribe((resp) => {
        if (!!resp.active) {
          let currentDate = new Date();
          this.chargebackDays2Go = Math.floor(((resp.active as UserChargeback).convertedDate.getTime() - currentDate.getTime()) / 1000 / 60 / 60 / 24) + 1;
          this.chargebackPercentFill = Math.floor((7 - this.chargebackDays2Go) / 7. * 100.)
          this.userChargeback = resp.active as UserChargeback;

        }
        if (!!resp.toClaim) {
          this.userChargebackToClaim = (resp.toClaim as UserChargeback);
        }
      })

    if (this.gamificationSubscription) this.gamificationSubscription.unsubscribe();
    this.gamificationSubscription = this.gamificationLevelService.getGamification(false, this.forceDbRefresh).pipe(
      takeUntil(this.unsubscribe),
      filter(resp => !!resp)
    ).subscribe((resp) => {
      this.gamificationPercentFill = resp?.turnover / resp?.levelEndsTurnover * 100
      this.gamificationLevel = resp?.levelNumber
      this.showGamification = true;
      this.gamificationIndex = ProfileInfoComponent.getLemonIconNameIndex(this.gamificationLevel);
      const index = Math.max(0, Math.min(Math.floor((this.gamificationLevel - 1) / 5), LEVEL_NAMES.length - 1));
      this.playerLoyalityName = LEVEL_NAMES[index];

    })

    this.prizeBoxService.getCurrentLevelLottery()
      .pipe(
        switchMap(() => this.prizeBoxService.getCurrentLevelLottery()),
        takeUntil(this.unsubscribe),
        filter(resp => resp !== null))
      .subscribe(resp => {
        this.isPrizeBoxActive = resp.isActive;
      });
  }

  unsubscribeData() {
    if (this.chargebackSubscription) this.chargebackSubscription.unsubscribe();
    if (this.gamificationSubscription) this.gamificationSubscription.unsubscribe();
    this.chargebackPercentFill = 0;
  }

  private subscribeToBonusLottery() {
    this.bonusLotteryService.getBonusLottery().pipe(
      take(1)
    ).subscribe(resp => {
      if (!!resp && resp.isActive)
        this.userBonusLotteryResponse = resp;
    });
  }

  public static getLemonIconNameIndex(gamifiactionIndex): number {
    return Math.ceil(gamifiactionIndex / 5);
  }
}
