export const LICENCE_CONFIG_MAP = {
    "default": {
        "logo-xmas": "assets/logo/lemon-logo-xmas-mobile.svg",
        "logo-desktop-xmas": "assets/logo/logo-xmas-desktop.svg",
        "logo": "assets/logo/logo-green.svg",
        "logo-desktop": "assets/logo/desktop-logo-125x32.svg",
        "logo-mobile": "assets/logo/mobile-logo-60x17.svg",
        // "logo-mobile" : "assets/logo/2024/logo-xmas-desktop.png",
        // "logo-desktop": "assets/logo/2024/logo-xmas-desktop.png",
        "full-logo": "assets/logo/logo-green.svg",
        "title-substitute": "",
        "icon-mission-logo": "assets/desktop/missions/tag-incomplete.svg",
        "icon-mission-completed": "assets/desktop/missions/tag-completed.svg",
        "arrow-icon": "assets/desktop/yellow-arrow.svg",
        "icon-edit-avater":"assets/icons/profile-edit-avater-button.svg",
        "icon-edit-no-outline-yellow":"assets/icons/icon-edit-no-outline-yellow.svg",
        "icon-adress":"assets/icons/adress.svg",
        "icon-exclamation": "assets/icons/icon-exclamation.svg",
        "icon-upload": "assets/icons/icon-upload.svg",
        "upload-docs-icon": "assets/images/upload-docs.svg",
        "pending-withdrawal": "assets/images/pending-withdrawal.svg",
        "icon-vip-leef":"assets/icons/vip-leaves.svg",
        "icon-ham-btn":"assets/desktop/icon-ham-btn-white.svg",
        "icon-wallet-bonus-black":"assets/icons/wallet-bonus-icon.svg",
        "icon-password-lock":"assets/icons/icon-password-lock.svg",
        "icon-payment-not-available":"assets/icons/icon-not-available-payment.svg",
        "icon-bonus-drop":"assets/icons/icon-bonus-drop.svg",
        "icon-copy":"assets/icons/icon-copy.svg",
        "icon-money-bag":"assets/icons/money-bag.svg",
        "icon-friends":"assets/icons/icon-friends.svg",
        "icon-avatar-friend":"assets/icons/icon-avatar-friend.png",
        "icon-tick-mark":"assets/icons/tick-mark.svg",
        "icon-reward-money-bag":"assets/icons/reward-money-bag.png",
        "icon-private":"assets/mobile/icon-private.svg",
        "icon-xmas-decor":"assets/icons/xmas/xmas-lemon-decor.png",
        "icon-xmas-lemon":"assets/icons/xmas/icon-xmas-lemon.png",
        "icon-loader-img":"assets/logo/logo-desktop.svg",
        "icon-logout":"assets/icons/icon_logout.svg",
        "icon-delete":"assets/icons/icon-delete.svg",
        "bonus-excluded":"assets/icons/bonus-excluded.svg",
    },
    "orange_curacao": {
        "logo": "assets/logo/orange-logo.svg",
        "logo-desktop": "assets/logo/orange-logo.svg",
        "full-logo": "assets/logo/orange-logo.svg",
        "title-substitute": "Orange Casino",

    }
}

