import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BaseHttpFirebaseService {

  getDocumentDBCallCount = 0;

  constructor(
    public afs: AngularFirestore,
  ) { }

  getDocumentDB<T>(path: string): Observable<T> {
    console.log(`$ BaseFirebaseService getDocumentDB() call count: ${this.getDocumentDBCallCount++}`, new Error().stack)
    return this.afs.doc<T>(path).valueChanges().pipe(
      // distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)),
      //tap((res) => console.log(`$ BaseFirebaseService getDocumentDB().valueChanges().pipe() => tap()`))
    );
  }

}
