<!-- <div class="game-container">
    <div *ngIf="data" fxLayoutAdivgn="center start" fxLayout="row" class="game-grid">
        <div class="thumbnail"><img alt="{{data.name}}" src="{{ data.thumbnail?.url || data?.thumbnailDesktop?.url }}"/></div>

        <div fxLayoutAdivgn="center start" class="game-title h16">
            {{data.name}}
        </div>

        <div class="action-icon">
            <mat-icon>keyboard_arrow_right</mat-icon>
        </div>
    </div>
</div> -->

<div class="game-card" [ngClass]="game.thumbnail?.url ? 'mobile': 'desktop'" (click)="onOpenGame(game)">
    <ng-container *ngIf="game.thumbnail?.url; else desktop">
        <img alt="{{game.name}}" src="{{ game.thumbnail.url }}" />
    </ng-container>

    <ng-template #desktop>
        <img alt="{{game.name}}" src="{{ game.thumbnailDesktopSquare.url }}" />
    </ng-template>
    <p style="padding-top: 0.25rem;">{{game.name}}</p>
    <div *ngIf="game?.tag?.includes('exclusive')" class="exclusive">
        <img src="assets/icons/tag-lemon-exclusive-desktop.svg" alt="tag-lemon-exclusive-desktop">
    </div>

    <div *ngIf="game?.tag?.includes('power_weekends')" class="power-weekends">
        <img src="assets/icons/tag-power-weekends-pragmatic-desktop-2x.png">
    </div>
    <div *ngIf="game?.tag?.includes('holiday_spin_splash')" class="holiday-spin-splash">
        <img src="assets/icons/holiday-spin-splash-desktop-tag-2x.png">
    </div>
    <div *ngIf="game?.tag?.includes('spin_gifts')" class="spin_gifts">
        <img src="assets/icons/spin-gifts-desktop-60x40.png">
    </div>
    <div *ngIf="game?.tag?.includes('reel_fortune')" class="reel_fortune">
        <img src="assets/icons/ROF-desktop-52x52.png">
    </div>
    <div *ngIf="game?.uniquePlayers" fxLayout="row" fxLayoutAlign="center center" class="unique-players">
        <img src="assets/icons/icon-profile-sticky.svg" loading=lazy alt="signup" class="profile-icon" />
        <span>{{game?.uniquePlayers}}</span>
    </div>
</div>