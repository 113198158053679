import { KeyValue } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { LICENCE_CONFIG } from 'src/app/app-routing.module';

export const LICENCE_CONFIG_CUSTOM_KEY = 'custom';
export const LICENCE_CONFIG_DEFAULT_KEY = 'default';
@Injectable({
    providedIn: 'root'
})
export class LicenceService {


    constructor(
        @Inject(LICENCE_CONFIG) private licenceConfig: KeyValue<string, KeyValue<string, string>>
    ) { }


    public getKey(key: string): string {
        return !!this.licenceConfig[LICENCE_CONFIG_CUSTOM_KEY] && !!this.licenceConfig[LICENCE_CONFIG_CUSTOM_KEY][key] ? this.licenceConfig[LICENCE_CONFIG_CUSTOM_KEY][key] : this.licenceConfig[LICENCE_CONFIG_DEFAULT_KEY][key];
    }
}