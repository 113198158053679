import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, EMPTY, Observable } from 'rxjs';
import { BaseFirebaseService } from '../http/base-firebase.service';
import { LoginStatusService } from '../auth/login/login-status.service';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { LoggedStatus } from '../auth/login/login.models';
import { HOST_NAME } from 'src/app/app-routing.module';
import { PrizeUser, SavedGameState } from './game-state.models';

@Injectable({
  providedIn: 'root'
})
export class GameStateService extends BaseFirebaseService {

  constructor(
    http: HttpClient,
    afAuth: AngularFireAuth,
    afs: AngularFirestore,
    private loginStatus: LoginStatusService,
    @Inject(HOST_NAME) public hostName
  ) {
    super(http, afAuth, afs, hostName);
  }

  private _store = new BehaviorSubject<SavedGameState>(null);
  public isDataLodaed = new BehaviorSubject<boolean>(false);

  public getGameStateStoreValue(): SavedGameState {
    return this._store.value;
  }

  public getLastGameState(): Observable<SavedGameState> {
    console.log('getLastGameState fucn start');

    let res = this._store.asObservable();
    console.log('getLastGameState func end');
    return res;

  }

  public refreshGameState(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
    console.log('refreshGameState func start');
    this.loginStatus.getLoginStatus().pipe(
      switchMap(
        (status) => {
          let res = this.getDocumentDB<PrizeUser>(`prize-users/${status.username}`).pipe(
            filter(resp => !!resp),
            map((resp: PrizeUser) => {
              if (!resp.gameState) {
                this.isDataLodaed.next(true);
                resolve();
              }
              return resp.gameState;
            })
          )
          return res;
        }
      ),
      filter(resp => !!resp),
      catchError(error => {
        return EMPTY;
      }),
      take(1),
    ).subscribe((resp: SavedGameState) => {
      this._store.next(resp);
      this.isDataLodaed.next(true);
      resolve();
    });
  });
}

  public addGameState(state: SavedGameState) {
    this.loginStatus.getLoginStatus().pipe(
      filter((status) => status.isLogged === LoggedStatus.logged),
      take(1),
      map((userInfo) => userInfo.username),
      tap((username) => {
        this.setDocument<{ gameState: SavedGameState }>({gameState: state}, `prize-users/${username}`, true);
      })
    )
    .subscribe((username) => {
      this._store.next(state);
    });
  }
}
