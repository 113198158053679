import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, switchMap, combineLatest } from 'rxjs';
import { BONUS_CANCEL, BONUS_LIST, BONUS_QUEUE, BONUS_QUEUE_REORDER } from '../http/base-http.service';
import { CookieService } from 'ngx-cookie-service';
import { BaseWalletService } from '../http/base-wallet.service';
import { HOST_NAME } from '../../app-routing.module';
import { filter, map } from 'rxjs/operators';
import { LoginStatusService } from '../auth/login/login-status.service';
import { LoggedStatus } from '../auth/login/login.models';
import { BonusQueueReorderRequest } from 'src/app/services/wallet/wallet.models';

import { Apollo, gql } from "apollo-angular";

const getBonusListQuery = gql`query BonusList($locale: Locale!, $name: [String]) {
  bonuses(where: {name_in: $name}, locales: [$locale, en]) {
    id, name, externalId, displayName,
    pagesNew{
      name
      category
    }
  }
}`

@Injectable({
  providedIn: 'root'
})
export class BonusQueueService extends BaseWalletService {


  constructor(http: HttpClient,
    cookie: CookieService,
    private apollo: Apollo,
    private loginStatus: LoginStatusService,
    @Inject(LOCALE_ID) public locale: string,
    @Inject(HOST_NAME) public hostName) {
    super(http, cookie, locale, hostName);
  }

  private getBonusListByUrl(url: string) {
    return this.loginStatus.getLoginStatus().pipe(
      filter(status => status.isLogged !== LoggedStatus.voidState),
      switchMap((status) => {
        if (status.isLogged === LoggedStatus.logged) {
          return this.get<any>(url).pipe(
            map(resp => {
              return resp.results;
            })
          )
        } else {
          return of([]);
        }
      }),
      switchMap(userBonuses => {
        return combineLatest([
          of(userBonuses),
          this.apollo.watchQuery<any>({
            query: getBonusListQuery,
            variables: {
              name: userBonuses.map(b => b.bonus_ref),
              locale: this.locale,
            }
          }).valueChanges.pipe()
        ]).pipe(
          map(([bonuses, cmsResponse]) => {
            const bonusesMap: Map<string, any> = new Map(
              cmsResponse.data.bonuses.map(b => [b.name, b])
            );
            bonuses.forEach(b => {
              if (bonusesMap.has(b.bonus_ref)) {
                b.bonus_name = bonusesMap.get(b.bonus_ref).displayName;
                let page = bonusesMap.get(b.bonus_ref).pagesNew[0];
                if (!!page) {
                  b.bonus_link = `/${page.category}/${page.name}`;
                }
              }
            });
            // bonuses.sort((a, b) => b.order - a.order);
            return bonuses;
          })
        )
      })
    )
  }

  public getBonusQueue() {
    return this.getBonusListByUrl(BONUS_QUEUE);;
  }

  public getBonusList() {
    return this.getBonusListByUrl(BONUS_LIST);
  }

  public cancelActiveBonus(): Observable<any> {
    return this.postEmptyRequest(BONUS_CANCEL)
  }

  public activateBonus(bonusId: number): Observable<any> {
    const bonusQueueReorderRequest: BonusQueueReorderRequest = {
      top: 1,
      item: bonusId
    };
    return this.postRequestJson(BONUS_QUEUE_REORDER, bonusQueueReorderRequest)
  }
}
