import { gql } from 'apollo-angular';

export const SEARCH_FILTERS_QUERY = gql`
query SearchFilters($locale: Locale!, $countryName: [Countries!]) { 
  categories: pagesCategories (
    where: {AND: {regionName: front, country_contains_some: $countryName}},
    locales: [$locale]
  ) {
    id
    name
    
    data: categories {
      id
      name
      displayName
    }
  }
  
  operators: pagesCategories (
    where: {AND: {regionName: front_operators, country_contains_some: $countryName}}
    locales: [$locale]
  ) {
    id
    name
    
    data: categories {
      id
      name
      displayName
    }
  }
}
`

export const SEARCH_GAME_QUERY = gql`
query SearchGame($locale: Locale!, $operatorsIn: [String]!, $categoriesIn: [String]!, $nameIn: String) {
  games(
    where: {
      AND: [
        { gameCategory_some: { name_in: $operatorsIn } }
        { gameCategory_some: { name_in: $categoriesIn }}]
    	name_contains: $nameIn
    }
    locales: [$locale, en], first: 50
  ) {
    id
    gameid
    name
    licence  
    systemName
    tag
    
    thumbnail {
      url(transformation: {
        image: { resize: { width: 206, height: 206, fit: clip } }
      })
    }

    gameProvider {
      id
      name
    }
    gameCategory {
      id
      name
      displayName
    }
  }
}
`

export function getSearchGameQuery(filterKeys: string[], isFullFilterMap: { [key: string]: boolean }): any {
  const filterConditions = filterKeys
    .filter((key) => !isFullFilterMap[key]) // Only include filters that are not marked as "full"
    .map((key) => `{ gameCategory_some: { name_in: $${key} }}`)
    .join(' ');

  const filterVariables = filterKeys
    .filter((key) => !isFullFilterMap[key]) // Only include variables that are not marked as "full"
    .map(key => `$${key}: [String]!`)
    .join(', ');

  return gql`
    query SearchGame($locale: Locale!, $nameIn: String${filterVariables ? ', ' + filterVariables : ''}) {
      games(
        where: {
          AND: [
            ${filterConditions}
            { name_contains: $nameIn }
          ]
        }
        locales: [$locale, en], first: 50
      ) {
        id
        gameid
        name
        licence
        systemName
        tag
        thumbnail {
          url(transformation: {
            image: { resize: { width: 206, height: 206, fit: clip } }
          })
        }
        gameProvider {
          id
          name
        }
        gameCategory {
          id
          name
          displayName
        }
      }
    }
  `;
}