import { Directive, Input } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { MatGridList } from '@angular/material/grid-list';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

export interface ResponsiveColumnsMap {
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
}
@Directive({
  selector: '[appResponsiveColumns]'
})
export class ResponsiveColumnsDirective {

  private static readonly DEFAULT_COLUMNS_MAP: ResponsiveColumnsMap = {
    xs: 3,
    sm: 4,
    md: 4,
    lg: 4,
    xl: 4
  };

  @Input() private responsiveColumns: ResponsiveColumnsMap;

  private readonly watchers: Subscription[] = [];

  constructor(private readonly grid: MatGridList,
    private readonly mediaObserver: MediaObserver) {
  }

  ngOnInit(): void {
    this.responsiveColumns = this.responsiveColumns || ResponsiveColumnsDirective.DEFAULT_COLUMNS_MAP;

    this.initializeColsCount();

    const mediaWatcher = this.mediaObserver.asObservable()
      .pipe(
        map(changes => {
          const matchingAliases = changes.map(change => this.mapAlias(change.mqAlias))
            // sort by number of columns desc
            .sort((a, b) => this.responsiveColumns[b] - this.responsiveColumns[a])
            // doublecheck
            .filter(alias => Object.keys(this.responsiveColumns).includes(alias))
            // triplecheck
            .filter(alias => this.mediaObserver.isActive(alias));

          const matchedAlias = matchingAliases.length > 0
            ? matchingAliases[0]     // take the first matching alias (most cols)
            : 'xs';                    // default to xs

          return this.responsiveColumns[matchedAlias];
        }),
      ).subscribe(cols => this.grid.cols = cols);

    this.watchers.push(mediaWatcher);
  }

  ngOnDestroy(): void {
    this.watchers
      .forEach(watcher => watcher.unsubscribe());
  }

  private initializeColsCount(): void {
    const matchingAliases = Object.keys(this.responsiveColumns)
      // sort by number of columns desc
      .sort((a, b) => this.responsiveColumns[b] - this.responsiveColumns[a])
      // doublecheck
      .filter(alias => this.mediaObserver.isActive(alias));

    if (matchingAliases.length > 0) {
      const firstMatchingAlias = matchingAliases[0];
      this.grid.cols = this.responsiveColumns[firstMatchingAlias];
    } else {
      this.grid.cols = this.responsiveColumns.xs;
    }
  }

  private mapAlias(mqAlias: string): string {
    if (!mqAlias.includes('-')) {
      return mqAlias;
    }


    const parts = mqAlias.split('-');
    const ltOrGt = parts[0];
    const alias = parts[1];

    const keys = Object.keys(this.responsiveColumns);
    const index = keys.indexOf(alias);
    return ltOrGt === 'lt'
      ? keys[index]
      : keys[index + 1];
  }

}
