import { Apollo } from 'apollo-angular';
import { Component, EventEmitter, Inject, LOCALE_ID, Output, PLATFORM_ID } from '@angular/core';
import { take, takeUntil } from 'rxjs/operators';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { SystemPage, SystemPageResponse } from 'src/app/apollo/models/base-models';
import { SYSTEM_PAGE_CONTENT_QUERY } from 'src/app/apollo/static-page';
import { BasePageComponent, BasePageComponentWithDialogs } from '../../base-page/base-page.component';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { LostPasswordService } from 'src/app/services/auth/lost-password/lost-password.service';
import { LostPasswordResponse } from 'src/app/services/auth/lost-password/lost-password.models';
import { DetectDeviceService } from 'src/app/services/utils/detect-device.service';
import { is } from 'immutable';
import { validate } from 'graphql';
import { MatDialog } from '@angular/material/dialog';
import { UserInfoService } from 'src/app/services/auth/check-session/user-info.service';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-lost-password',
  templateUrl: './lost-password.component.html',
  styleUrls: ['./lost-password.component.scss']
})
export class LostPasswordComponent extends BasePageComponentWithDialogs {

  page: SystemPage;
  body: SafeHtml;
  bodyLast: SafeHtml;
  formSubmited: boolean = false;
  errorMsg: string;
  hasError: boolean = false;
  isDesktop: boolean = this.deviceService.isDesktop();
  lostPasswordResponse: LostPasswordResponse;
  isSubmited: boolean = false;
  verificationCode: boolean = false;
  newPassword: boolean = false;
  success: boolean = false;
  hidePassword = true;
  enterEmail = $localize`:@@enter-email:Enter your email address`;


  lostPasswordForm = this.formBuilder.group({
    mail: ['', [Validators.required, Validators.email]],
  });

  lostPasswordFormCode = this.formBuilder.group({
    code: ['', [Validators.required, Validators.maxLength(6), Validators.minLength(6)]],
    email: ['', []],
  });

  lostNewPasswordForm = this.formBuilder.group({
    new_password: ['', [Validators.required]],
    new_password_repeat: ['', [Validators.required]],
  });

  @Output() closeEvent = new EventEmitter();

  constructor(private apollo: Apollo,
    private formBuilder: UntypedFormBuilder,
    private lostPasswordService: LostPasswordService,
    @Inject(LOCALE_ID) public locale: string,
    @Inject(PLATFORM_ID) public platformId: string,
    @Inject(DOCUMENT) public document: Document,
    private sanitizer: DomSanitizer,
    public deviceService: DetectDeviceService,
    private userInfoService: UserInfoService,
    public errorDialog: MatDialog,
  ) { super(errorDialog, deviceService) }

  initialize() {
    this.apollo.watchQuery<SystemPageResponse>({
      query: SYSTEM_PAGE_CONTENT_QUERY,
      variables: {
        pageName: "lost-password",
        locale: this.locale
      }
    }).valueChanges.pipe(take(1),).subscribe((resp) => {
      this.page = resp.data.systemPage;
      // this.body = this.sanitizer.bypassSecurityTrustHtml(this.page.content.html);
      let template = this.document.createElement("template");
      template.innerHTML = this.page.content.html;
      this.body = template.content.firstChild.textContent;
      this.bodyLast = template.content.lastChild.textContent;

    }, (error) => {

    });
  }

  ngOnInit(): void {
    if (isPlatformBrowser) {
      this.initialize();
    }
    // this.errorMsg = "lkasjdlkajslkjdlkasd   sadkl"
    // this.hasError = true
    // this.isSubmited = true
    // this.newPassword = true;
    // this.openErrorDialog(this.errorMsg);

  }

  onSubmit(lostPasswordForm: FormGroup) {
    if (this.formSubmited) return;
    if (this.lostPasswordForm.controls.mail.errors) return;
    this.errorMsg = null;
    this.formSubmited = true;
    this.isSubmited = true;


    this.lostPasswordService.requestLostPasswordinUser(lostPasswordForm).pipe(takeUntil(this.unsubscribe)).subscribe((resp: LostPasswordResponse) => {
      this.lostPasswordResponse = resp;
      this.lostPasswordFormCode.get('email').setValue(lostPasswordForm.get('mail').value)
      console.log('complite')
      this.verificationCode = true;
      this.formSubmited = false;
    }, err => {
      this.formSubmited = false;
      this.isSubmited = false;

      if (err.status === 406) {
        this.errorMsg = err.error.error_message;
        this.openErrorDialog(this.errorMsg);
        this.hasError = true;
        // console.log(this.errorMsg);
      }
    });
  }

  onSubmitCode(lostPasswordFormCode: FormGroup) {
    if (this.formSubmited) return;
    if (this.lostPasswordFormCode.controls.code.errors) return;
    this.errorMsg = null;

    this.lostPasswordService.sendVerificationCode(lostPasswordFormCode).pipe(take(1)).subscribe((resp: LostPasswordResponse) => {
      this.lostPasswordResponse = resp;
      this.formSubmited = false;
      this.verificationCode = false;
      this.newPassword = true;
    }, err => {
      this.formSubmited = false;
      this.verificationCode = true;
      this.newPassword = false;
      if (err.status === 406) {
        this.errorMsg = err.error.error_message;
        this.hasError = true;
        console.log(this.errorMsg);
        this.openErrorDialog(this.errorMsg);

      }
      else {
        this.openErrorDialog();
      }
    });
  }

  onSubmitPassword(lostNewPasswordForm: FormGroup) {
    this.lostNewPasswordForm.get('new_password_repeat').setValue(lostNewPasswordForm.get('new_password').value)
    if (this.formSubmited) return;
    if (this.lostNewPasswordForm.controls.new_password.errors) return;
    this.errorMsg = null;
    this.formSubmited = true;
    this.lostPasswordService.setNewPassword(lostNewPasswordForm).pipe(take(1)).subscribe((resp: LostPasswordResponse) => {
      this.newPassword = false;
      this.success = true;

      this.userInfoService.requestCheckUserInfo().pipe(
        take(1),
      ).subscribe(resp => {
        next: {
        }
        error: (err) => {
          this.errorDialog.open(err);
        }
      })
    }, err => {
      this.formSubmited = false;
      this.newPassword = true;
      if (err.status === 406) {
        this.errorMsg = err.error.error_message;
        this.hasError = true;
        console.log(this.errorMsg);
        this.openErrorDialog(this.errorMsg);
      }
    });
  }

  closeDialog() {
    this.closeEvent.next('/')
  }
}
