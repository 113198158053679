import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-mobile-page-back-header',
  templateUrl: './mobile-page-back-header.component.html',
  styleUrls: ['./mobile-page-back-header.component.scss']
})
export class MobilePageBackHeaderComponent implements OnInit {

  constructor() { }

  @Input() iconName;
  @Input() iconUrl;
  @Input() hideIcon : boolean = false;
  @Input() position : string = 'left';

  ngOnInit(): void {
  }

}
