import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { UntypedFormGroup } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { BaseHttpService } from './base-http.service';
import { CookieService } from 'ngx-cookie-service';
import { CustomEncoder } from 'src/app/interceptors/encode-http.interceptor';
import { HOST_NAME } from 'src/app/app-routing.module';

const flattenForm = (result, values) => {
    Object.keys(values).forEach(k => {
        if ((typeof values[k]) == 'object' && values[k] !== null) {
            flattenForm(result, values[k]);
        }
        else {
            result[k] = values[k]
        }
    })
}

@Injectable({
    providedIn: 'root'
})
export class BasePaymentService extends BaseHttpService {

    constructor(http: HttpClient, protected cookie: CookieService, @Inject(LOCALE_ID) public locale: string, @Inject(HOST_NAME) public hostName) {
        super(http, cookie, locale)
        environment.production ? super.setApiUrl(environment.paymentUrl + hostName) : super.setApiUrl(environment.paymentUrl);
    }

    protected postRequest<T>(url: string, formGroup: UntypedFormGroup) {
        const httpOptionsDefault = {
            headers: this.createHeaders(),
            withCredentials: true
        };
        let result = {}
        const values = formGroup.value
        flattenForm(result, values);

        const params = new HttpParams({
            encoder: new CustomEncoder(),
            fromObject: result
        });

        return this.http.post<T>(this.apiUrl + url, params, { ...httpOptionsDefault });
    }

    get<T>(url: string) {
        const httpOptionsDefault = {
            headers: this.createHeaders(),
            withCredentials: true,
            params: new HttpParams().set('t', new Date().getTime().toString())
        };
        return this.http.get<T>(this.apiUrl + url, { ...httpOptionsDefault })

    }

    getWithParams<T>(url: string, params: HttpParams) {
      params = params.set('t', new Date().getTime().toString());
      const httpOptionsDefault = {
          headers: this.createHeaders(),
          withCredentials: true,
          params: params
      };
      return this.http.get<T>(this.apiUrl + url, { ...httpOptionsDefault })
    }

    getOriginalUrl<T>(url: string) {
        const httpOptionsDefault = {
            headers: this.createHeaders(),
            withCredentials: true
        };
        return this.http.get<T>(url, { ...httpOptionsDefault })
    }

    getOriginalUrlWithParams<T>(url: string, params: HttpParams) {
        const httpOptionsDefault = {
            headers: this.createHeaders(),
            withCredentials: true,
            params: params
        };
        return this.http.get<T>(url, { ...httpOptionsDefault })
    }

}
