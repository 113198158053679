import { Component, Inject, Input, LOCALE_ID, PLATFORM_ID } from '@angular/core';
import { BasePageComponent } from '../../base-page/base-page.component';
import { liveCasinoTrx, providerTrx } from 'src/app/router-translation.labels';
import { TranslationConfig } from 'src/app/utils/translate-config';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, take, takeUntil } from 'rxjs';
import { Apollo, gql } from 'apollo-angular';
import { GameCategories, GameCategory, InAppPageParams } from 'src/app/apollo/models/base-models';
import { COUNTRY_ID, LICENCE } from 'src/app/app-routing.module';
import { isPlatformServer } from '@angular/common';
import { countryFromLocale } from 'src/app/utils/country-from-locale';
import { FrontPageGamesService } from 'src/app/services/page/front-games.service';
import SwiperCore, { Grid, SwiperOptions } from 'swiper';
import {
  LIVE_CASINO_OPERATOR_REGION,
  LIVE_CASINO_PAGE_GAMES_QUERY
} from '../../live-casino-category-page/live-casino-category-page.component';
import { LicenceService } from 'src/app/services/utils/licence.service';

const query = gql`query InAppPageParamsQuery($locale: Locale!, $licenceName: Licence!){
inAppPagesParams(where: {AND: {siteName:"provider", OR: [{licence_contains_some: [all,$licenceName]}] }}){
    seo(locales: [$locale, en]) {
          metaTitle
          metaDescription
          relCanonical
      }
  }
}
`

SwiperCore.use([Grid]);
@Component({
  selector: 'app-game-operator-short',
  templateUrl: './operator.component.html',
  styleUrls: ['./operator.component.scss']
})
export class GameOperatorCategoryComponent extends BasePageComponent {
  constructor(
    protected translationConfig: TranslationConfig,
    protected apollo: Apollo,
    protected frontPageGamesService: FrontPageGamesService,
    @Inject(LOCALE_ID) public locale: string,
    @Inject(COUNTRY_ID) public countryId: string,
    @Inject(LICENCE) public licence: string,
    @Inject(PLATFORM_ID) protected platformId,
    protected route: ActivatedRoute,
    protected router: Router,
    protected licenceService: LicenceService,
  ) { super(); }

  public providerUrl: string = this.translationConfig.getTranslation(providerTrx);

  isAll = false;
  isliveCasino = false;
  isInitialized = false;
  isServer: boolean;
  isLiveCasinoPage = this.router.url.endsWith(this.translationConfig.getTranslation(liveCasinoTrx));
  isProviderAll = this.router.url.endsWith(this.translationConfig.getTranslation(liveCasinoTrx) + '/' + this.translationConfig.getTranslation(providerTrx));

  config: SwiperOptions = {
    grid: {
      rows: 2,
    },
    slidesPerView: 3,
    centerInsufficientSlides: false,
    spaceBetween: 10,
    lazy: true,
    preloadImages: false,
  };

  @Input() set operators(operators: GameCategory[]) {
    this.data = this.isServer ? operators?.slice(0, 6) : operators;
  }

  get operators(): GameCategory[] {
    return this.data;
  }

  initialize(isDesktop = false) {
    this.route
      .data.pipe(takeUntil(this.unsubscribe))
      .subscribe(data => {
        console.log("is all", data)
        if (!!data.isAll && !data.isliveCasino) {
          this.isAll = true;
          combineLatest({
            categories: this.frontPageGamesService.fetchData(),
            inAppPageParams: this.apollo
              .watchQuery<{ inAppPagesParams: InAppPageParams[] }>({
                query: query,
                variables: {
                  locale: this.locale,
                  licenceName: this.licence,
                }
              })
              .valueChanges
          })
            .pipe(take(1)).subscribe((response) => {
              console.log('GameOperatorCategoryComponent initialize query', response)
              this.data = response.categories.operators[0]?.categories;
              const inAppparams = response.inAppPageParams.data.inAppPagesParams.filter(item => !!item.licence.find(licence => licence === this.licence))[0] || response.inAppPageParams.data.inAppPagesParams[0]

              this.setSEOTags(inAppparams?.seo?.metaTitle, inAppparams?.seo?.metaDescription, inAppparams?.seo?.relCanonical)
            });
          // } else if (this.isServer) {
          //   this.data = this.data?.slice(0, 6);
        } else if (!!data.isliveCasino) {
          this.isliveCasino = true;
          this.isAll = true;
          const query = LIVE_CASINO_PAGE_GAMES_QUERY;
          this.apollo
            .watchQuery<{
              inAppPagesParams: InAppPageParams[],
              operators?: GameCategories[] & {
                thumbnailDesktop?: {
                  url: string
                },
                thumbnailMobile?: {
                  url: string
                }
              }
            }>({
              query,
              variables: {
                licenseName: this.licence,
                locale: this.locale,
                countryName: [countryFromLocale(this.locale)],
                operatorsRegion: LIVE_CASINO_OPERATOR_REGION
              }
            })
            .valueChanges.pipe(
              take(1),
            ).subscribe((response) => {
              this.data = response.data.operators[0]?.categories;
              const inAppparams = response.data.inAppPagesParams.filter(item => !!item.licence.find(licence => licence === this.licence))[0] || response.data.inAppPagesParams[0]

              this.setSEOTags(inAppparams?.seo?.metaTitle, inAppparams?.seo?.metaDescription, inAppparams?.seo?.relCanonical)
              this.isInitialized = true;
            });
        }
      });
  }

  ngOnInit(): void {
    if (this.isLiveCasinoPage) {
      this.config.grid.rows = 1;
    }
    this.isServer = isPlatformServer(this.platformId);
    this.initialize();
  }

  onProvidersClick() {
    if (this.router.url.includes(this.translationConfig.getTranslation(liveCasinoTrx))) {
      return [this.providerUrl];
    }
    return ['', this.providerUrl];
  }
}
