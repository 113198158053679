export const environment = {
  production: true,
  enableServiceWorker: false,
  apiUrl: 'https://api.',
  walletUrl: 'https://api.',
  paymentUrl: 'https://api.',
  uiBackend: 'https://prizeengine2.',
  fallbackLanguage: "en",
  affiliateProgram: 'https://lemon.partners/',
  cmsApi: 'https://eu-central-1-lemon-casino.cdn.hygraph.com/content/clac9rrgn4tb401uma47z2n9n/master',
  cmsDraftToken: null,
  baseCurrency: "EUR",
  enableTournamentsDisplay: true,
  enablePaymentIQ: true,
  firebaseConfig: {
    apiKey: "AIzaSyCQ38_g0cC7QMkZkX-B02sTDzrUa-3kn0I",
    authDomain: "citron-294513.firebaseapp.com",
    databaseURL: "https://citron-294513.firebaseio.com",
    projectId: "citron-294513",
    storageBucket: "citron-294513.appspot.com",
    messagingSenderId: "49223945288",
    appId: "1:49223945288:web:10ada036ea46a1009edc7d",
    measurementId: "G-L32YGC9Y4D"
  },
  paymentIqConfig: {
    url: 'https://api.paymentiq.io/paymentiq/api',
    mid: 100539001,
    sessionIdName: 'citrin_ses',
    userPrefix: 'user'
  },
  checkSessionInterval: 5000,
  checkInboxInterval: 10000,
  checkPopularGamesUsersInterval: 60000,
  uniquePlayerMultiplier: 3,
  minBalanceThresholds: {
    USD: 10,
    EUR: 10,
    GBP: 10,
    PLN: 20,
    HUF: 1500,
    INR: 500,
    CAD: 30,
    AUD: 32,
    NZD: 35,
    ZAR: 150,
    NOK: 100
  },
  defaultDepositValues: {
    PLN: [50, 100, 200, 500, 1000],
    USD: [20, 50, 100, 200, 500],
    EUR: [20, 50, 100, 200, 500],
    GBP: [20, 50, 100, 200, 500],
    HUF: [5000, 10000, 20000, 50000, 100000],
    INR: [500, 1000, 2000, 5000, 10000],
    CAD: [20, 50, 100, 200, 500],
    AUD: [20, 50, 100, 200, 500],
    NZD: [20, 50, 100, 200, 500],
    ZAR: [150, 300, 500, 1000, 2000, 5000],
    NOK: [200, 500, 1000, 2000, 5000]
  }
};
