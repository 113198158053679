import { bonusQueueTrx, liveCasinoTrx } from 'src/app/router-translation.labels';
import { aviatorTrx } from './../../router-translation.labels';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationEnd, Router, UrlSegment } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { Breadcrumb } from './breadcrumb.model';
import { TranslationConfig } from '../../utils/translate-config';
import {
  bonusTrx,
  clientAreaMainTrx,
  clientAreaTrx,
  gameInfoTrx,
  infoTrx,
  newGamesTrx,
  promoTrx,
  providerTrx,
  tournamentsIdTrx,
  treasureBoxesTrx
} from '../../router-translation.labels';
import { NOT_A_LINK_BREADCRUMB } from 'src/app/utils/not-a-link-breadcrumbs';
import { NavigationService } from '../navigation/navigation.service';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {

  // Mapping between the route and the name
  private breadcrumbsMapping: Map<string, string> = new Map([
    ['/', 'Home'],
    [providerTrx, 'Top Providers'],
    [newGamesTrx, 'Newest Games'],
    [clientAreaMainTrx, "Profile"]
  ]);

  //Mapping between the breadcrumb and oter breadcrumb that should be added before it
  private breadcrumbsToAddBefore: Map<string, string> = new Map([
    [treasureBoxesTrx, 'promotions']
  ]);

  // Mapping between the breadcrumb and oter breadcrumb that should be replaced after it
  private breadcrumbsToReplaceAfter: Map<string, string> = new Map([
    [tournamentsIdTrx, infoTrx]
  ]);

  // List of the routes that should not be displayed in breadcrumbs
  private breadcrumbsToSkip: string[] = [
    infoTrx,
    clientAreaTrx,
    bonusQueueTrx
  ];

  // List of the routes that should use history context
  private trxToCheck: string[] = [
    gameInfoTrx,
    promoTrx,
    bonusTrx,
    clientAreaTrx,
    providerTrx
  ];

  // Subject emitting the breadcrumb hierarchy
  private readonly breadcrumbsSubject = new BehaviorSubject<Breadcrumb[]>([]);

  // Observable exposing the breadcrumb hierarchy
  readonly breadcrumbsObservable = this.breadcrumbsSubject.asObservable();

  constructor(
    private router: Router,
    private navigationService: NavigationService,
    private translationConfig: TranslationConfig,
    @Inject(LOCALE_ID) private locale: string
  ) {
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      distinctUntilChanged()
    ).subscribe(event => {
      const breadcrumbs: Breadcrumb[] = [];
      const root = this.router.routerState.snapshot.root;
      const snapshotUrl = this.router.routerState.snapshot.url.split('?')[0];

      if (this.trxToCheck.some(url => this.router.url.includes(url) || this.router.url.includes(translationConfig.getTranslation(url)))) {
        const itemName = this.router.url.substring(this.router.url.lastIndexOf('/') + 1).split("?")[0];

        let previousUrl = this.router.url.includes(aviatorTrx) ? '' : this.navigationService.subjectPrevUrl.value.replace('/', '');

        if (previousUrl.includes(translationConfig.getTranslation(providerTrx))) {
          const snapshotUrlSegments = this.getUrlSegments(snapshotUrl);
          const previousUrlSegments = this.getUrlSegments(previousUrl);

          if (this.areSegmentsEqual(snapshotUrlSegments, previousUrlSegments)) {
            previousUrl = previousUrl.substring(0, previousUrl.lastIndexOf('/'));

          }
        }

        if (snapshotUrl.includes(translationConfig.getTranslation(providerTrx)) && !snapshotUrl.endsWith(providerTrx)) {
          previousUrl = previousUrl || translationConfig.getTranslation(providerTrx);
        }
        const previousUrlPaths = previousUrl !== ('') ? previousUrl.split('/') : [];

        if (previousUrl === liveCasinoTrx && this.router.url.includes(providerTrx) && !this.router.url.endsWith(providerTrx)) {
          previousUrlPaths.push(providerTrx);
        }
        previousUrlPaths.push(itemName);
        this.addBreadcrumbs(previousUrlPaths, breadcrumbs);
      } else {

        if (root.url.length > 0) {
          this.addBreadcrumbsBySnapshot(root, breadcrumbs);
        } else {
          this.addBreadcrumbs(snapshotUrl === '/' ? [] : snapshotUrl.startsWith('/') ? snapshotUrl.split('/').slice(1) : snapshotUrl.split('/'), breadcrumbs);
        }
      }
      this.breadcrumbsSubject.next(breadcrumbs);
    });
  }

  private addBreadcrumbs(routePaths: string[], breadcrumbs: Breadcrumb[]) {
    if (routePaths != null && routePaths.length > 0) {
      breadcrumbs.push(...this.getBreadcrumb(routePaths, this.translationConfig));
    }
  }

  private addBreadcrumbsBySnapshot(route: ActivatedRouteSnapshot, breadcrumbs: Breadcrumb[]) {
    const routePaths = route.children.map(child => child.url.map(segment => segment.path))[0];
    this.addBreadcrumbs(routePaths, breadcrumbs);
  }

  private getLabel(path: string): string {
    path = path.substr(path.lastIndexOf('/') + 1);
    return this.breadcrumbsMapping.get(path) ?? path.split('-').map((s) => s.charAt(0).toUpperCase() + s.substring(1)).join(' ');
  }

  private getBreadcrumb(routePaths: string[], translationConfig: TranslationConfig): Breadcrumb[] {
    const breadcrumbs: Breadcrumb[] = [];
    // Adding home path
    breadcrumbs.push({
      label: translationConfig.getTranslation(this.breadcrumbsMapping.get('/')),
      name: this.breadcrumbsMapping.get('/').toUpperCase(),
      url: `/`
    });
    let stopIteration = false;
    routePaths
      .filter(path => !this.breadcrumbsToSkip.includes(translationConfig.getTranslation(path)))
      .forEach((path, index) => {
        if (stopIteration) {
          return;
        }
        const value = translationConfig.translateToOriginal(path);
        const offset = routePaths.some(path => this.breadcrumbsToSkip.includes(translationConfig.getTranslation(path))) ? 2 : 1;;
        let fullUrl = `/${routePaths.slice(0, index + offset).join('/')}`;
        fullUrl = value ? fullUrl.replace(path, translationConfig.getTranslation(value)) : fullUrl;
        const label = this.getLabel(path);

        const previousBreadcrumb = this.breadcrumbsToAddBefore.get(path);
        if (previousBreadcrumb) {
          breadcrumbs.push({
            label: this.getLabel(translationConfig.getTranslation(previousBreadcrumb)),
            name: previousBreadcrumb,
            url: fullUrl.substring(0, fullUrl.lastIndexOf("/") + 1) + previousBreadcrumb
          });
        }

        const nextBreadcrumb = this.breadcrumbsToReplaceAfter.get(path);
        if (nextBreadcrumb) {
          breadcrumbs.push({
            label: this.getLabel(translationConfig.getTranslation(nextBreadcrumb)),
            name: nextBreadcrumb,
            url: !NOT_A_LINK_BREADCRUMB.includes(path) ? fullUrl : null
          });
          stopIteration = true;
        }
        else {
          breadcrumbs.push({
            label: translationConfig.getTranslation(label),
            name: path,
            url: !NOT_A_LINK_BREADCRUMB.includes(path) ? fullUrl : null
          });
        }
      });

    return breadcrumbs;
  }

  private getUrlSegments(url: string): UrlSegment[] {
    const urlTree = this.router.parseUrl(url);
    return urlTree.root.children.primary.segments;
  }

  private areSegmentsEqual(segments1: UrlSegment[], segments2: UrlSegment[]): boolean {
    if (segments1.length !== segments2.length) {
      return false;
    }

    for (let i = 0; i < segments1.length - 1; i++) {
      if (segments1[i].path !== segments2[i].path) {
        return false;
      }
    }

    return true;
  }
}
