<ng-template #bonusNotificationSnackBar>
  <div fxLayoutAlign="space-between center">
    <div><span i18n>You have an active bonus that may affect your balance. Check</span>&nbsp;<span class="snack-bar-link" (click)="closeSnackBar()" [topDrawerRouterLink]="['client-area','bonus-queue']" i18n>My
        Bonuses</span></div>
    <!-- <div> -->
      <img (click)="closeSnackBar()" src="assets/icons/notification-close.svg"/>
    <!-- </div> -->
  </div>
</ng-template>

<ng-template #tournamentNotificationSnackBar>
  <div fxLayoutAlign="space-between center">
    <div i18n>You joined a tournament! Please note that <span>minimal bet is 0.05 EUR&nbsp;{{notificationVariables.bet}}</span></div>
    <!-- <div><span i18n>You joined a tournament! Please note that minimal bet is</span>&nbsp;{{notificationVariables.bet}}</div> -->
    <!-- <div> -->
      <img (click)="closeSnackBar()" src="assets/icons/notification-close.svg"/>
    <!-- </div>  -->
  </div>
</ng-template>