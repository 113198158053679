<div class="box-wrapper" fxLayoutAlign="center center">
    <div class="tournament-box" [ngStyle]="!!tournament.thumbnailMobile && {'background-image': getImageUrl()}" [style]="isRegularTournament()?.tournamentType === 2 || isFinished?{'filter':'saturate(0.1) brightness(0.3)'}:''" [ngClass]="(isDesktop && !isModalDisplay) ? 'desktop': 'mobile'" (click)="checkAndOpen()" fxLayoutAlign="space-between center">
        <div class="info" fxFlex="56" fxLayout="column" fxLayoutAlign="space-between">
            <!-- <div>
                <img *ngIf="tournament.tournamentType === 0" class="live-icon" src="assets/mobile/badge-icon-0.svg">
                <img *ngIf="tournament.tournamentType === 1" class="live-icon" src="assets/mobile/badge-icon-1.svg">
                <img *ngIf="tournament.tournamentType === 2" class="live-icon" src="assets/mobile/badge-icon-2.svg">
                <img *ngIf="tournament.isPrivate" class="live-icon" src="assets/mobile/locked.svg.svg">
            </div> -->
            <ng-container *ngIf="isRegularTournament() as regularTournament">
                <div class="badge-container batch-bg-0" *ngIf="regularTournament.tournamentType === 0 && !regularTournament.isPrivate">
                    <img class="live-icon" src="assets/mobile/badge-icon-0.svg" alt="badge-icon-0">
                    <span i18n>Live</span>
                </div>
                <div class="badge-container batch-bg-1" *ngIf="regularTournament.tournamentType === 1">
                    <img class="live-icon" src="assets/mobile/badge-icon-1.svg" alt="badge-icon-1">
                    <span i18n>Starts Soon!</span>
                </div>
                <!-- <div class="badge-container batch-bg-2" *ngIf="tournament.tournamentType === 2">
                <img class="live-icon" src="assets/mobile/badge-icon-3.svg">
                <span i18n>Completed</span>
            </div> -->
                <div class="badge-container batch-bg-3" *ngIf="regularTournament.isPrivate">
                    <img class="live-icon" src="assets/mobile/icon-locked.svg">
                    <span i18n>Locked</span>
                </div>
            </ng-container>
            <ng-container *ngIf="isNotRegularTournament() && !isFinished">
                <div class="badge-container batch-bg-9">
                    <img class="live-icon" src="assets/mobile/icon-tournament-network.svg" alt="icon-tournament-network">
                    <span i18n>Network</span>
                </div>
            </ng-container>
            <div>
                <div class="label" [innerHTML]="tournament.name | safeHtml"></div>
            </div>
            <ng-container *ngIf="!isFinished">
                <div class="wrapper" *ngIf="isRegularTournament()?.tournamentType === 0 || isNotRegularTournament()">
                    <div class="time">
                        <img class="hour-glass" src="assets/mobile/hour-glass.svg">
                        <ng-container *ngIf="tournamentStartDateLocal<=now">
                            <div #tournamentstartdate></div>
                        </ng-container>
                        <ng-container *ngIf="tournamentStartDateLocal>now">
                            {{ tournamentStartDateLocal | date : 'short':'':locale
                            }}<br />
                            <span i18n>Starting soon</span>
                        </ng-container>
                    </div>
                    <div class="dynamic-text">{{game}}</div>
                </div>
            </ng-container>
            <div class="wrapper" *ngIf="isRegularTournament()?.tournamentType === 1">
                <div class="time">{{ tournamentStartDateLocal | date : 'short':'':locale
                    }}
                </div>
                <div class="dynamic-text">{{game}}</div>
            </div>
            <div *ngIf="isRegularTournament()?.tournamentType === 2 || isFinished" class="wrapper">
                <div i18n>Finished</div>
                <div class="time">{{ tournamentEndDateLocal | date : 'short':'':locale}}</div>
            </div>
        </div>
    </div>
</div>

<ng-template #finished>
    <div i18n>Finished</div>
</ng-template>