export const LICENCE_CONFIG_MAP = {
    "default": {
        "logo-xmas": "assets/logo/lemon-logo-xmas-mobile.svg",
        "logo-desktop-xmas": "assets/logo/logo-xmas-desktop.svg",
        "logo": "assets/logo/logo-green.svg",
        "logo-desktop": "assets/logo/desktop-logo-125x32.svg",
        "logo-mobile": "assets/logo/mobile-logo-60x17.svg",
        // "logo" : "assets/logo/2023/logo-xmas-mobile.svg",
        // "logo-desktop": "assets/logo/2023/logo-xmas-desktop.svg",
        "full-logo": "assets/logo/logo-green.svg",
        "title-substitute": "",
        "icon-mission-logo": "assets/desktop/missions/tag-incomplete.svg",
        "icon-mission-completed": "assets/desktop/missions/tag-completed.svg",
        "arrow-icon": "assets/desktop/yellow-arrow.svg",
        "icon-edit-avater":"assets/icons/profile-edit-avater-button.svg",
        "icon-edit-no-outline-yellow":"assets/icons/icon-edit-no-outline-yellow.svg",
        "icon-adress":"assets/icons/adress.svg",
        "icon-exclamation": "assets/icons/icon-exclamation.svg",
        "icon-upload": "assets/icons/icon-upload.svg",
        "upload-docs-icon": "assets/images/upload-docs.svg",
        "pending-withdrawal": "assets/images/pending-withdrawal.svg",
        "icon-vip-leef":"assets/icons/vip-leaves.svg",
        "icon-ham-btn":"assets/desktop/icon-ham-btn-white.svg",
        "icon-wallet-bonus-black":"assets/icons/wallet-bonus-icon.svg",
        "icon-password-lock":"assets/icons/icon-password-lock.svg",
        "icon-payment-not-available":"assets/icons/icon-not-available-payment.svg",
        "icon-bonus-drop":"assets/icons/icon-bonus-drop.svg",
    },
    "orange_curacao": {
        "logo": "assets/logo/orange-logo.svg",
        "logo-desktop": "assets/logo/orange-logo.svg",
        "full-logo": "assets/logo/orange-logo.svg",
        "title-substitute": "Orange Casino",

    }
}

