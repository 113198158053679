import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SwiperModule } from 'swiper/angular';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedMaterialModule } from '../shared-material/shared-material.module';
import { RouterModule } from '@angular/router';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { SharedComponentsModule } from '../shared-components/shared-components.module';
import { SearchGameComponent } from '../../components/games/search-game/search-game.component';
import {
  SearchGameResultComponent
} from '../../components/games/search-game/search-results/search-game-result/search-game-result.component';
import { 
  SearchFilterSelectorComponent
} from 'src/app/components/games/search-game/search-filter-selector/search-filter-selector.component';
import { SEARCH_MODULE_NOT_TRANSLATED } from './search-routes';
import { SearchFilterSheetComponent } from '../../components/games/search-game/search-filter-sheet/search-filter-sheet.component';
import { SearchFilterChipsComponent } from '../../components/games/search-game/search-filter-chips/search-filter-chips.component';
import { SearchResultSectionComponent } from 'src/app/components/games/search-game/search-results/search-result-section/search-result-section.component';
import { SearchGameSwiperComponent } from 'src/app/components/games/search-game/search-game-swiper/search-game-swiper.component';
import { MatChipsModule } from '@angular/material/chips';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';


@NgModule({
  declarations: [
    SearchGameResultComponent,
    SearchFilterSelectorComponent,
    SearchFilterSheetComponent,
    SearchFilterChipsComponent,
    SearchGameResultComponent,
    SearchResultSectionComponent,
    SearchGameSwiperComponent,
  ],
  imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		MatChipsModule,
		MatCheckboxModule,
		MatButtonModule,
		SwiperModule,
		SharedComponentsModule,
    MatIconModule,
  ],
  exports: [
    SearchGameResultComponent,
    SearchFilterSelectorComponent,
    SearchFilterSheetComponent,
    SearchFilterChipsComponent,
    SearchGameResultComponent,
    SearchResultSectionComponent,
    SearchGameSwiperComponent,
  ]
})
export class SharedSearchModule {
  
}