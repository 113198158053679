<div class="mobile-x-panel expansion-panel" [ngClass]="isDesktop===true?'desktop':'mobile'">
  <!-- <mat-accordion [multi]="true">
    <mat-expansion-panel *ngFor="let item of data" [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
        </mat-panel-title>
      </mat-expansion-panel-header>
    </mat-expansion-panel>
  </mat-accordion> -->
  <ng-container *ngIf="!isServer else ssrPanel">
    <h4 class="panel-content" *ngIf="!!data && data[0]?.title">{{!!data && data[0]?.title}}</h4>
    <div [innerHtml]="!!data && data[0]?.content.safehtml " class="panel-content"></div>
  </ng-container>

  <ng-template #ssrPanel>
    <h4 class="panel-content" *ngIf="!!data && data[0]?.title">{{!!data && data[0]?.title}}</h4>
    <div [innerHtml]="!!data && data[0]?.content.safehtml" class="panel-content"></div>
  </ng-template>
</div>