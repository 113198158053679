import { Component, Input } from '@angular/core';
import { UserCampaignService } from 'src/app/services/campaigns/campaign.service';
import { BaseComponent } from '../../base/base.component';
import { DetectDeviceService } from 'src/app/services/utils/detect-device.service';
import { reactivationInfoTrx } from 'src/app/router-translation.labels';
import { TranslationConfig } from 'src/app/utils/translate-config';
import { Router } from '@angular/router';


@Component({
  selector: 'app-reactivation-info',
  templateUrl: './reactivation-info.component.html',
  styleUrls: ['./reactivation-info.component.scss']
})
export class ReactivationInfoComponent extends BaseComponent {
  public reactivationInfoTrx = this.translationConfig.getTranslation(reactivationInfoTrx);

  isCompleted: boolean;
  currency: string;
  numberPrizes: number;
  endDate: string;
  reactivationData: any;
  
  @Input() item: any;
  @Input() index: any;
  @Input() currentRecursivePointer: any;

  constructor(
    public userCampaignService: UserCampaignService,
    public detectDeviceService: DetectDeviceService,
    protected translationConfig: TranslationConfig,
    public router: Router,
  ) { super() }


  ngOnInit(): void {
  };

  infoRoute(index) {
    this.router.navigate([reactivationInfoTrx, index]);
  }

}
