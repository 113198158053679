import { enableProdMode, LOCALE_ID } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import {
  ALL_TRANSLATED_ROUTES,
  COUNTRY_ID,
  DESKTOP_ROUTE_TOKEN,
  DEVICE_TYPE,
  HOST_NAME,
  LICENCE,
  LICENCE_CONFIG,
  licences,
  TRANSLATED_ROUTES,
  USER_REGION
} from './app/app-routing.module';
import { environment } from './environments/environment';
import { LICENCE_CONFIG_MAP } from './app/config/licence.config';
import { DEVICE_TYPE_MODEL } from './app/apollo/models/base-models';

if (environment.production) {
  enableProdMode();

  // Sentry.init({
  //   dsn: "https://74149006b96d45c2b14188be4fb977b1@o503842.ingest.sentry.io/5589489",
  //   autoSessionTracking: true,
  //   release: version,
  //   integrations: [
  //     new Integrations.BrowserTracing({
  //       tracingOrigins: ["https://lemoncasino.io/"],
  //       routingInstrumentation: Sentry.routingInstrumentation,
  //     }),
  //     new RewriteFrames(),

  //   ],

  //   // We recommend adjusting this value in production, or using tracesSampler
  //   // for finer control
  //   tracesSampleRate: 1.0,
  // });
}
else {

}


const countryFromLocale = (locale) => {
  let splitLocale = locale.split('-');
  return splitLocale.length > 1 ? splitLocale[1] : splitLocale[0];
}


document.addEventListener('DOMContentLoaded', () => {
  const pathArray = window.location.pathname.split('/');
  const countryCode = window['countryCode'];
  const userRegion = window['userRegion'];

  let deviceType: DEVICE_TYPE_MODEL;
  if (!window['deviceType']) {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      deviceType = DEVICE_TYPE_MODEL.MOBILE;
    } else {
      deviceType = DEVICE_TYPE_MODEL.DESKTOP;
    }
  }
  else {
    deviceType = window['deviceType'] === 'd' ? DEVICE_TYPE_MODEL.DESKTOP : DEVICE_TYPE_MODEL.MOBILE;
  }
  // deviceType: DEVICE_TYPE_MODEL = DEVICE_TYPE_MODEL.DESKTOP;
  const fallbackLanguage = !!environment.fallbackLanguage ? environment.fallbackLanguage : "en";
  const locale = pathArray[1].match(/^([a-zA-Z]{2})(-[A-Z]{2})?$/gm) ? pathArray[1] : fallbackLanguage;
  let hostnameArray = window.location.hostname.split('.');
  const hostname = hostnameArray.length > 2 ? hostnameArray?.slice(1).join('.') : window.location.hostname;
  const licenceName = licences[hostname] || 'curacao';
  fetch(`assets/i18n/config.json`).then(response => {
    return response.json();

  })
    .then(data => {
      function renderDevice(desktopRoutes = []) {
        platformBrowserDynamic([
          { provide: TRANSLATED_ROUTES, useValue: data[locale] || {} },
          { provide: ALL_TRANSLATED_ROUTES, useValue: data },
          { provide: HOST_NAME, useValue: hostname.startsWith("localhost") ? "" : hostname },
          { provide: DESKTOP_ROUTE_TOKEN, useValue: desktopRoutes },
          { provide: LOCALE_ID, useValue: locale },
          { provide: COUNTRY_ID, useValue: countryCode || countryFromLocale(locale) },
          { provide: USER_REGION, useValue: userRegion || '' },
          { provide: LICENCE, useValue: licenceName },
          { provide: DEVICE_TYPE, useValue: deviceType },
          { provide: LICENCE_CONFIG, useValue: { ...{ "default": LICENCE_CONFIG_MAP['default'] }, ...{ "custom": LICENCE_CONFIG_MAP[licenceName] } } },
        ]).bootstrapModule(AppModule).catch(err => console.log(err));
      }

      if (deviceType === DEVICE_TYPE_MODEL.DESKTOP) {
        import('./app/components/desktop/desktop.module').then(m => m.DesktopModule).then(m => {
          renderDevice(m.routes);
        })
      } else if (deviceType === DEVICE_TYPE_MODEL.MOBILE) {
        renderDevice([]);
      }

      navigator.serviceWorker.getRegistrations().then(function (registrations) {
        console.log("removing service workers", registrations);
        for (let registration of registrations) {
          registration.unregister()
        }
      })
        .catch(err => console.log(err));
    })

});
