import { Component, EventEmitter, Input, Output } from '@angular/core';
import { GameItem } from 'src/app/apollo/models/base-models';
import { BasePageComponent } from 'src/app/components/base-page/base-page.component';
import { DetectDeviceService } from 'src/app/services/utils/detect-device.service';

@Component({
  selector: 'app-search-game-result',
  templateUrl: './search-game-result.component.html',
  styleUrls: ['./search-game-result.component.scss']
})
export class SearchGameResultComponent extends BasePageComponent {

  public game: GameItem;

  @Input() _game;
  @Output() openGameEvent = new EventEmitter<string>();

  constructor( ) { super() }

  ngOnInit() {
    this.game = this._game;
  }

  onOpenGame(game: GameItem) {
    this.openGameEvent.next(game.systemName);
  }

}
