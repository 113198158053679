<div class="filter-sheet-wrapper" #scrollContainer>
  <div class="filter-sheet-head"> <!-- arrow, title, head -->
    <img class="back-arrow" src="assets/icons/icon-arrow-down.svg" alt="icon-arrow-down" (click)="onCloseEvent()">

    <h1>{{filterItemDisplay.displayHeader}}</h1>

    <button class="close-sheet" (click)="onCloseEvent()">
      <img src="assets/desktop/close-icon.svg" alt="close-btn" />
    </button>
  </div>

  <app-search-filter-chips [_filterName]="filterItemDisplay.name"></app-search-filter-chips>

  <div class="filter-sheet-body">
    <ng-container *ngIf="filterItemDisplay">
      <ng-container *ngFor="let item of filterItemDisplay.data">
        <ng-container *ngIf="item.displayName">
          <div class="filter-sheet-container">
            <mat-checkbox class="large-checkbox" [(ngModel)]="item.selected" (change)="onCheckboxChange()">
              <p style="color: #DADADA;">{{ item.displayName }}</p>
            </mat-checkbox>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>

  <div [ngClass]="{ 'filter-sheet-footer': true, 'mobile': !isDesktop, 'desktop': isDesktop }"> <!-- fix button -->
    <ng-container *ngIf="isSelected">
      <button round-button class="confirm-btn" filled="filled" color="warn" (click)="onCloseEvent()">
        <ng-container i18n>confirm</ng-container>
        <div *ngIf="filterItemDisplay.activeFilters > 0" class="count-container">
          <p style="color: #FFD36C;">{{filterItemDisplay.activeFilters}}</p>
        </div>
      </button>
    </ng-container>

    <ng-container *ngIf="!isSelected">
      <button round-button class="confirm-btn" style="background-color: #1B2827;" color="warn" (click)="onCloseEvent()">
        <ng-container i18n>back</ng-container>
      </button>
    </ng-container>
  </div>
</div>