import { SafeHtml } from '@angular/platform-browser';

export interface SEO {
    metaTitle: string;
    metaDescription: string;
    relCanonical?: string;
}

export type Page = {
    name: string,
    category: string,
    localizedName: string,
    displayName?: string,
    content?: {
        html: string
    },
    htmlContent: string,
    pageType?: string,
    pageAdditionalContents?: {
        name: string,
        content?: {
            html: string
        }
        url: string
    }[],
    title?: string,
    description?: string
    landingPage?: boolean
    seo?: {
        metaTitle: string;
        metaDescription: string;
        relCanonical?: string;
        noIndex?: boolean
    }
    headerBanner?: {
        url: string
    }
    desktopHeaderBanner?: {
        url: string
    }
}

export type PageResponse = {
    pages: Page[]
}

export type CardWidgetPageResponse = {
    cardWidgetPages: {
        country?: string[],
        cardWidgetElements: {
            isLargeCard: boolean,
            isDepositButton: boolean | null,
            name: string;
            title: string,
            thumbnail: {
                url: string
            }
            thumbnailMobile?: {
                url: string
            }
            shortDescription: string,
            page: {
                name: string,
                category: string
            }
        }
        expansionPanel?: ExapnsionPanel,
        seo?: {
            metaTitle: string;
            metaDescription: string;
            relCanonical?: string;
        }
    }[]
    labels: string[]
}

export type SystemPage = {
    name: string,
    header?: string,
    content?: {
        html: string
    }
}

export type SystemPageResponse = {
    systemPage: SystemPage
}

export type MenuItem = {
    name: string;
    page?: Page;
    iconName?: string;
    itemColor?: {
        hex: string;
    }
    requireLogin: boolean;
    inAppUrl?: string;
    icon?: {
        url: string
    }
    colorName?: string;
};

export type Language = {
    name: string,
    fullName: Page,
    flag: {
        url: string
    }
    licence?: string
};

export type Footer = {
    footerLinks: Page[],
    footerExternalLinks?: {
        name: string,
        url: string
    },
    imageWithLinks?: {
        name: string,
        url: string,
        image: {
            url: string
        }
    }
    licenceDescription: string
    licenceLogo?: {
        url: string
    }[]
}

export interface PageMapping {
    name: string
    localizedName: string
    category: string
}

export type TopMenuResponse = {
    topMenuLists: {
        menuItem: MenuItem[];
        licence?: string;
    }[],
    languages: Language[]
    footers: Footer[],
    maintenance: {
        enabled: boolean
    }

    __type: {
        enumValues: {
            name: string
        }[]
    }
    pageScripts: {
        name: string,
        script: string
    }[]
};

export type GameWithPrividerExclusionTerritories = {
    name: string,
    hideOperator: boolean,
    providerExclusionTerritories: {
        countryCode: string
        state: string
    }[]
}



export type GameItem = {
    name: string;
    id: string
    provider: string
    systemName: string
    hideGame: boolean
    shortDescription?: {
        html: string
        safe: SafeHtml
    };
    gameid: string;
    initialLoadColor?: {
        css
    }
    tag?: string[]
    seo?: {
        metaTitle: string;
        metaDescription: string;
        relCanonical?: string;
    }
    thumbnail?: {
        url: string
    };
    gameProvider?: GameWithPrividerExclusionTerritories,
    thumbnailDesktop?: {
        url: string
    };
    thumbnailDesktopSquare: {
        url: string
    };
    thumbnailDesktopRectangular: {
        url: string
    };
    gameCategory?: {
        __typename: string;
        gameType: string;
        name: string;
    }[];
    animation?: {
        url: string;
        fileName: string;
    }[];
    uniquePlayers?: number;
    licence?: string
};

export const DESKTOP_RECTANGULAR_DISPLAY = "rectangular";
export const DESKTOP_SQUARE_DISPLAY = 'square';


export enum DEVICE_TYPE_MODEL {
    DESKTOP = 'd',
    MOBILE = 'm',
}

export enum DesktopDisplayType {
    DESKTOP_SQUARE_DISPLAY,
    DESKTOP_RECTANGULAR_DISPLAY
}
export interface GameCategory {
    id: string,
    name: string,
    displayName: string,
    url?: string,
    game: GameItem[],
    displayType: "rectangular" | 'square',
    numberGamesFrontPageMobile?: number,
    numberGameRowsDesktop?: number,
    expansionPanel?: ExapnsionPanel,
    gameType?: string,
    numberGameRows?: number,
    icon?: {
        url: string
    },
    thumbnail?: {
        url: string
    }
    initialLoadColor?: {
        css
    }
    seo?: {
        metaTitle?: string
        metaDescription?: string
    }
}

export type GameCategories = {
    categories: GameCategory[]
    seo?: {
        metaTitle: string,
        metaDescription: string
    }
}

export type GameCategoryResponse = {
    gameCategories: GameCategory[];
}

export type FrontPageGamesResponse = {
    pagesCategories: GameCategories[],
    operators?: GameCategories[] & {
        thumbnailDesktop?: {
            url: string
        },
        thumbnailMobile?: {
            url: string
        }
    }

};

export type GameResponse = {
    game: GameItem
}

interface BannerLocalizedImage {
    image: {
        url: string
    }
}

export interface BannerItem {
    name: string
    image: {
        url: string;
    }
    desktopImage: {
        url: string;
    }
    leftBackground?: {
        url: string;
    }
    rightBackground?: {
        url: string;
    }
    buttonLabel?: string,
    loggedButtonLabel?: string,
    hideForLogged?: boolean,
    hideForNotLogged?: boolean,
    description?: string,
    titleText?: string,
    buttonLink?: string,
    loggedButtonLink?: string,
    bannerLocalizedImages?: [
        {
            image: {
                url: string
            }
        }
    ]
    desktopNewImage?: {
        url: string;
    }
    originalDesktopNewImage?: {
        url: string;
    }
    originalMobileNewImage?: {
        url: string;
    }
    forceOriginalImage?: boolean
    mobileNewImage?: {
        url: string;
    }
    mobileVideo?: {
        url: string;
    }
}

type BannerItems = {
    bannerItems: BannerItem[]
}

export type Banner = {
    banner: BannerItems
};

export type ExpansionPanelItem = {
    content: {
        text: string;
        html: string;
        safehtml: SafeHtml
    }
    title: string,
    display: boolean
}


export type ExapnsionPanels = {
    expansionPanels: ExapnsionPanel[]
}

export type ExapnsionPanel = {
    item: ExpansionPanelItem[]
    licence: string
};


export type initialData = {
    topMenuItems: MenuItem[],
    topMenuBanner: BannerItem,
    languages: Language[],
    footer: Footer
};

export interface InAppPageParams {
    name?: string;
    seo: SEO
    expansionPanel?: ExapnsionPanel
    licence?: string[]
}


