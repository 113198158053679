import { AfterViewInit, ChangeDetectorRef, Component, Inject, Input, PLATFORM_ID, ViewChild, effect } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SwiperComponent } from 'swiper/angular';
import { BaseGamePage } from '../../games/ase-game-page.component';
import { MediaMatcher } from '@angular/cdk/layout';
import { MatGridList } from '@angular/material/grid-list';
import { DetectDeviceService } from 'src/app/services/utils/detect-device.service';
import { isPlatformServer } from '@angular/common';
import SwiperCore, { Grid, SwiperOptions, Virtual } from 'swiper';
import { LoginStatusService } from 'src/app/services/auth/login/login-status.service';
import { take } from 'rxjs';
import { PopularGamesService } from 'src/app/services/profile/popular-games.service';
import { MostPlayedGameStatistic } from 'src/app/services/profile/profile.models';
import { environment } from 'src/environments/environment';

SwiperCore.use([Grid, Virtual]);

@Component({
  selector: 'app-desktop-front-page-game',
  templateUrl: './front-page-games.component.html',
  styleUrls: ['./front-page-games.component.scss'],
})
export class DesktopFrontPageGamesComponent extends BaseGamePage implements AfterViewInit {

  static IDENTIFIER: string = 'games';
  isServer: boolean;
  private _numberOfRows: number = 1;
  private mostPlayedGamesStatistics: MostPlayedGameStatistic[] = [];
  // iteration:number = 6;
  @Input() size: string;
  @Input() categoryName: string;

  @Input() iteration: number;
  @Input() set numberOfRows(rows: number) {
    this._numberOfRows = rows || 1;
    this.initSwiper();
  };

  get numberOfRows(): number {
    return this._numberOfRows;
  }

  constructor(
    @Inject(PLATFORM_ID) private platformId,
    public dialog: MatDialog,
    changeDetectorRef: ChangeDetectorRef,
    public detectDeviceService: DetectDeviceService,
    private loginStatusService: LoginStatusService,
    private popularGamesService: PopularGamesService,
    media: MediaMatcher
  ) {
    super(dialog);
    if (!isPlatformServer(this.platformId)) {
      effect(() => {
        this.mostPlayedGamesStatistics = this.popularGamesService.popularGames();
        this.addGamesStatistics();
      });
    }
  }

  gamesSwiperConfig: SwiperOptions;

  private readonly responsiveColumns = {
    0: 3,
    374: 4,
  };

  @ViewChild('gamegrid') gameGrid: MatGridList;
  @ViewChild('swipercomponent', { static: false }) swiper?: SwiperComponent;

  flexValue = 33;
  flexWidth = 15.52;
  isLoggedIn: boolean = false;

  ngOnInit(): void {
    this.loginStatusService.getIfUserLogged().pipe(take(1)).subscribe((isLogged) => {
      this.isLoggedIn = true;
    })
    this.isServer = isPlatformServer(this.platformId);
    // this.screenWidth = window.innerWidth;
    this.flexValue = this.detectDeviceService.isDesktop() ? 25 : 33;
    this.flexWidth = this.size === 'rectangular' ? 19 : 15.52;
    this.initSwiper();
  }

  ngAfterViewInit(): void {

    // setTimeout(()=>this.changeScreenSize());
    // fromEvent(window, 'resize').pipe(
    //   takeUntil(this.unsubscribe),
    //   debounceTime(100),
    // ).subscribe( evt => {
    //   this.screenWidth = window.innerWidth;
    //   this.changeScreenSize();
    // })
  }

  changeScreenSize() {
    let cols = 0;
    Object.entries(this.responsiveColumns).forEach(arr => {
      if (arr[0] < this.screenWidth) {
        cols = arr[1];
      }
    });
    this.gameGrid.cols = cols;
  }

  public screenWidth: any;

  // @HostListener('window:resize', ['$event'])
  // onResize(event) {

  // }

  private initSwiper() {
    const slidesCount = this.size === 'rectangular' ? 5 : 6;
    const rowCount = this.numberOfRows;
    this.gamesSwiperConfig = {
      direction: 'horizontal',
      slideToClickedSlide: true,
      mousewheel: false,
      scrollbar: false,
      keyboard: true,
      freeMode: false,
      slidesPerView: slidesCount,
      centerInsufficientSlides: false,
      centeredSlides: false,
      grid: {
        rows: rowCount,
      },
      loop: false,
      setWrapperSize: true,
      spaceBetween: 8,
      pagination: false,
      observer: true,
      observeParents: true,
      parallax: true,
      preloadImages: false,
      lazy: {
        loadPrevNext: true,
        loadPrevNextAmount: rowCount,
      },
      watchSlidesProgress: true,
    };
  }

  swipeLeft() {
    if (this.isServer) {
      return;
    }
    this.swiper?.swiperRef.slidePrev();
  }

  swipeRight() {
    if (this.isServer) {
      return;
    }
    this.swiper?.swiperRef.slideNext();
  }

  addGamesStatistics() {

    if (this.mostPlayedGamesStatistics.length > 0 && this.data && this.categoryName === "most played") {
      this.mostPlayedGamesStatistics.forEach((game) => {
        this.data.forEach((gameItem) => {
          if (gameItem.gameid === game.externalId) {
            gameItem.uniquePlayers = game.uniquePlayers * environment.uniquePlayerMultiplier;
          }
        });
      });
    }
  }

}
