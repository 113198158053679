import { Attribute, Directive, ElementRef, OnInit, Renderer2 } from "@angular/core";

@Directive({ selector: '[icon-btn]' })
export class IconButtonDirective implements OnInit {
    direction: any;
    size: any;
    filled: boolean = false;

    constructor(
        private renderer: Renderer2,
        private elementRef: ElementRef,
        @Attribute('direction') direction = 'left',
        @Attribute('size') size: string = 'default',
        @Attribute('filled') filled = 'filled',
    ) {
        this.direction = direction;
        this.size = size;
        this.filled = filled === 'filled' ? true : false;
    }

    ngOnInit(): void {
        this.renderer.addClass(this.elementRef.nativeElement, 'icon-button');

        // icon button direction check state
        if (this.direction === 'left') {
            this.renderer.addClass(this.elementRef.nativeElement, `rotate-180`);
        } else if (this.direction === 'top') {
            this.renderer.addClass(this.elementRef.nativeElement, `rotate-270`);
        } else if (this.direction === 'right') {
            this.renderer.addClass(this.elementRef.nativeElement, `right-class`);
        } else if (this.direction === 'bottom') {
            this.renderer.addClass(this.elementRef.nativeElement, `rotate-90`);
        }

        // icon button size check state
        if (this.size === 'small') {
            this.renderer.addClass(this.elementRef.nativeElement, `small`);
        } else if (this.size === 'medium') {
            this.renderer.addClass(this.elementRef.nativeElement, `medium`);
        } else if (this.size === 'large'){
            this.renderer.addClass(this.elementRef.nativeElement, `large`);
        } else {
            this.renderer.addClass(this.elementRef.nativeElement, `medium`);
        }

        if (this.filled) {
            // this.renderer.addClass(this.elementRef.nativeElement, 'background-color', '#FFD36C');
            this.renderer.addClass(this.elementRef.nativeElement, 'filled');
        }
    }
}