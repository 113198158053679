import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { takeUntil } from 'rxjs';
import { GameItem } from 'src/app/apollo/models/base-models';
import { BasePageComponent } from 'src/app/components/base-page/base-page.component';
import { GameCategory, SearchConfigFilterItem, SearchGameService } from 'src/app/services/games/search-game.service';

@Component({
  selector: 'app-search-result-section',
  templateUrl: './search-result-section.component.html',
  styleUrls: ['./search-result-section.component.scss']
})
export class SearchResultSectionComponent extends BasePageComponent implements OnInit {

  public categoryList: GameCategory[];
  public filterItems: SearchConfigFilterItem[];

  @Input() _categoryList: GameCategory[];
  @Output() openGameEvent = new EventEmitter<string>();

  constructor(
    private searchGamesService: SearchGameService,
  ) {
    super()
  }

  ngOnInit() {
    this.categoryList = this._categoryList.map(category => ({
      ...category,
      game: category.game.slice(0, 3)
    }));

    console.log('this.categoryList', this.categoryList)

    this.searchGamesService.searchFilters$.pipe(takeUntil(this.unsubscribe)).subscribe((filterItems) => {
      this.filterItems = filterItems;
    });
  }

  onSelecEvent(category: GameCategory) {
    console.log(category);

    this.filterItems = this.filterItems.map((filterItem) => {
      const updatedData = filterItem.data.map((item) => {
        if (item.name === category.name) {
          return { ...item, selected: true };
        }
        return item;
      });

      return { ...filterItem, data: updatedData };
    });

    this.searchGamesService.setSearchFilters(this.filterItems);
  }

  onOpenGame(game: string) {
    this.openGameEvent.next(game);
  }

}
