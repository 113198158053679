import { ChangeDetectorRef, Component } from '@angular/core';
import { BasePageComponent } from 'src/app/components/base-page/base-page.component';
import { BalanceService } from 'src/app/services/wallet/balance.service';
import { TranslationConfig } from 'src/app/utils/translate-config';
import { depositTrx, paymentTrx } from 'src/app/router-translation.labels';
import { BalanceInAppInfo } from 'src/app/services/wallet/wallet.models';
import { environment } from 'src/environments/environment';
import { Subscription, switchMap, takeUntil, timer } from 'rxjs';
import { BalanceStatusService } from 'src/app/services/wallet/balance-status.service';


@Component({
  selector: 'app-account-balance',
  templateUrl: './account-balance.component.html',
  styleUrls: ['./account-balance.component.scss']
})
export class AccountBalanceComponent extends BasePageComponent {

  paymentUrl: string[] = [this.translationConfig.getTranslation(paymentTrx), this.translationConfig.getTranslation(depositTrx)];
  balanceInfo: BalanceInAppInfo;
  timerSubscription$: Subscription;
  realBalanceValue: boolean;
  bonusBalanceValue: boolean;

  constructor(
    protected translationConfig: TranslationConfig,
    private balanceService: BalanceStatusService,
    private changeDetect: ChangeDetectorRef,
  ) { super() }

  ngOnInit(): void {
    this.timerSubscription$ = timer(0, environment.checkSessionInterval).pipe(
      switchMap(() => this.balanceService.getBalanceInfoStatus().pipe(takeUntil(this.unsubscribe))),
      takeUntil(this.unsubscribe),
    ).subscribe((balanceResp) => {
      this.realBalanceValue = Math.floor(balanceResp.balance) === 0;
      this.bonusBalanceValue = Math.floor(balanceResp.bonus_balance) === 0;
      this.balanceInfo = balanceResp;
      this.changeDetect.markForCheck();
    })
  }

  ngOnDestroy(): void {
    this.timerSubscription$?.unsubscribe();
  }

}
