import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DetectDeviceService } from 'src/app/services/utils/detect-device.service';
import { DESKTOP_LOGIN, DialogConfig, FULL_DIALOG_CONFIG } from '../../dialog/dialog.config';


@Component({
  template: ''
})
export class LostPasswordDialogRouteComponent implements OnInit {
  constructor(public dialog: MatDialog,
    private router: Router,
    private detectDeviceService: DetectDeviceService) { }

  dialogSize: DialogConfig = FULL_DIALOG_CONFIG;

  ngOnInit(): void {
    if (this.detectDeviceService.isDesktop()) {
      this.dialogSize = DESKTOP_LOGIN
    }
    this.openDialog();
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(LostPasswordDialogComponent, {
      ...this.dialogSize,
      data: {},
      backdropClass: "blur-backdrop",
      panelClass: ["transparent", "auth-card-container"],
    });
    dialogRef.afterClosed().subscribe(result => {
      this.router.navigate([dialogRef.componentInstance?.redirectUrl || ""]);
    });
  }
}


@Component({
  selector: 'app-lost-password-dialog',
  templateUrl: './lost-password-dialog.component.html',
  styleUrls: ['./lost-password-dialog.component.scss']
})
export class LostPasswordDialogComponent implements OnInit {

  public isDesktop: boolean;

  constructor(
    public dialogRef: MatDialogRef<LostPasswordDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { msg: string },
    public detectDeviceService: DetectDeviceService
  ) { }

  public redirectUrl: string = null;

  closeDialog(redirectUrl?: string): void {
    if (redirectUrl) this.redirectUrl = redirectUrl;

    this.dialogRef.close();
  }

  ngOnInit(): void {
    this.isDesktop = this.detectDeviceService.isDesktop()
  }

}
