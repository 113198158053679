import { Directive, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { LoginStatusService } from '../services/auth/login/login-status.service';

@Directive({
    selector: '[loggedRender]'
})
export class LoggedRenderDirective implements OnInit {

    _isCreated: boolean = false;
    constructor(
        private viewContainer: ViewContainerRef,
        private templateRef: TemplateRef<any>,
        private userInfoLoginService: LoginStatusService,
    ) {

    }

    ngOnInit() {
        this.userInfoLoginService.getLoginPartialStatus().subscribe((resp) => {
            if (!resp) {
                this.viewContainer.clear();
            }
            else {
                this.viewContainer.clear();
                this.viewContainer.createEmbeddedView(this.templateRef);
            }
        })
    }
}
