import { Apollo } from 'apollo-angular';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';

import { switchMap, take } from 'rxjs/operators';
import { FRONT_PAGE_GAMES_DESKTOP_QUERY, FRONT_PAGE_GAMES_QUERY } from '../../apollo/front-page-games/front-page-games';
import { FrontPageGamesResponse } from '../../apollo/models/base-models';
import { BehaviorSubject, Observable } from 'rxjs';
import { countryFromLocale } from 'src/app/utils/country-from-locale';
import { LICENCE } from 'src/app/app-routing.module';
import { DetectDeviceService } from '../utils/detect-device.service';

@Injectable({
    providedIn: 'root'
})
export class FrontPageGamesService {

    constructor(
        private apollo: Apollo,
        public detectDeviceService: DetectDeviceService,
        @Inject(LOCALE_ID) public locale: string,
        @Inject(LICENCE) public licence: string,
    ) { }

    private _response = new BehaviorSubject<FrontPageGamesResponse>(null);


    fetchData(): Observable<FrontPageGamesResponse> {
        if (this._response.value) {
            return this._response.asObservable();
        }
        else {
            const query = this.detectDeviceService.isDesktop() ? FRONT_PAGE_GAMES_DESKTOP_QUERY : FRONT_PAGE_GAMES_QUERY;
            return this.apollo
                .watchQuery<FrontPageGamesResponse>({
                    query: query,
                    variables: {
                        licenseName: this.licence,
                        locale: this.locale,
                        countryName: [countryFromLocale(this.locale)]
                    }
                })
                .valueChanges.pipe(
                    take(1),
                    switchMap((response) => {
                        this._response.next(response.data);
                        return this._response.asObservable();
                    }
                    ),
                );
        }
    }
}
