import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { UntypedFormGroup } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { BaseHttpService } from './base-http.service';
import { CookieService } from 'ngx-cookie-service';
import { CustomEncoder } from '../../interceptors/encode-http.interceptor';
import { HOST_NAME } from 'src/app/app-routing.module';

@Injectable({
    providedIn: 'root'
})
export class BaseUsermgrService extends BaseHttpService {



    constructor(http: HttpClient, protected cookie: CookieService, @Inject(LOCALE_ID) public locale: string, @Inject(HOST_NAME) public hostName) {
        super(http, cookie, locale)
        const apiUrl = environment.production ? environment.apiUrl + this.hostName : environment.apiUrl;
        super.setApiUrl(apiUrl);
    }

    protected postRequest<T>(url: string, formGroup: UntypedFormGroup) {
        return this.postRequestJson<T>(url, formGroup.value);
    }

    protected postRequestJson<T>(url: string, json: any) {
        const httpOptionsDefault = {
            headers: this.createHeaders(),
            withCredentials: true
        };
        const params = new HttpParams({
            encoder: new CustomEncoder(),
            fromObject: json
        });

        // const formData = new FormData();
        // Object.keys(formGroup.value).forEach(key => {
        //     formData.append(key,formGroup.value[key]);
        // });

        return this.http.post<T>(this.apiUrl + url, params, { ...httpOptionsDefault });
    }

    get<T>(url: string) {
        const httpOptionsDefault = {
            headers: this.createHeaders(),
            withCredentials: true,
            params: new HttpParams().set('t', new Date().getTime().toString())
        };
        return this.http.get<T>(this.apiUrl + url, { ...httpOptionsDefault })
    }

    delete<T>(url: string) {
        const httpOptionsDefault = {
            headers: this.createHeaders(),
            withCredentials: true,
            params: new HttpParams().set('t', new Date().getTime().toString())
        };
        return this.http.delete<T>(this.apiUrl + url, { ...httpOptionsDefault })
    }

    options<T>(url: string) {
        const httpOptionsDefault = {
            headers: this.createHeaders(),
            withCredentials: true
        };
        return this.http.options<T>(this.apiUrl + url, { ...httpOptionsDefault })

    }



}
