import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  _value: number;

  @Input()
  set value(value: number) {
    this._value = value > 0 ? value : 1;
  }
  get value(): number {
    return this._value;
  }


}
