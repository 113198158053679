import { AfterViewInit, Component, TemplateRef, ViewChild } from '@angular/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { combineLatest } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { LoginStatusService } from 'src/app/services/auth/login/login-status.service';
import { LoggedStatus } from 'src/app/services/auth/login/login.models';
import { NotificationQueueElement, NotificationVariables } from 'src/app/services/notofications/notifications.models';
import { UserNotificationService } from 'src/app/services/notofications/user-notification.service';
import { BalanceStatusService } from 'src/app/services/wallet/balance-status.service';
import { BasePageComponent } from '../../base-page/base-page.component';


@Component({
  selector: 'app-user-notifications',
  templateUrl: './user-notifications.component.html',
  styleUrls: ['./user-notifications.component.scss']
})
export class UserNotificationsComponent extends BasePageComponent implements AfterViewInit {

  constructor(
    private balanceStatusService: BalanceStatusService,
    private loginStatusService: LoginStatusService,
    private snackBar: MatSnackBar,
    private userNotoficationService: UserNotificationService,
  ) { super() }



  @ViewChild('bonusNotificationSnackBar') bonusNotificationSnackBar: TemplateRef<any>;
  @ViewChild('tournamentNotificationSnackBar') tournamentNotificationSnackBar: TemplateRef<any>;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  notificationVariables: NotificationVariables;



  notificationQueue: NotificationQueueElement[] = []
  processingMessage: boolean = false;

  ngOnInit(): void {



  }

  ngAfterViewInit(): void {
    combineLatest([this.loginStatusService.getLoginStatus(), this.loginStatusService.getUserState(), this.balanceStatusService.getBalanceInfoStatus()]).pipe(
      filter(([status, state, balance]) => status.isLogged === LoggedStatus.logged),
      filter(([status, state, balance]) => !state.activeBonusWarningDisplayed),
      filter(([status, state, balance]) => balance.bonus_balance > 0),
      takeUntil(this.unsubscribe),
    ).subscribe(resp => {
      Promise.resolve().then(() => {
        this.loginStatusService.udpateUserState('activeBonusWarningDisplayed', true)
        this.notify({ templateName: "bonusNtofication" })
      });
    })

    this.userNotoficationService.getMessages().pipe(
      filter(msg => !!msg),
      takeUntil(this.unsubscribe)
    ).subscribe((msg: NotificationQueueElement) => {
      this.notify(msg);
    })
  }

  closeSnackBar() {
    this.snackBar.dismiss();
  }

  private notify(msg: NotificationQueueElement) {
    this.notificationQueue.push({ "templateName": msg.templateName, "notificationVariables": msg.notificationVariables });

    if (!this.processingMessage) {
      this.displaySnackbar();
    }
  }


  private displaySnackbar(): void {
    const templateMap = {
      "bonusNtofication": this.bonusNotificationSnackBar,
      "tournamentNotification": this.tournamentNotificationSnackBar
    }

    const nextMessage: NotificationQueueElement = this.getNextMessage();

    if (!nextMessage) {
      this.processingMessage = false;
      this.notificationVariables = null;
      return;
    }

    this.processingMessage = true;
    this.notificationVariables = nextMessage.notificationVariables;

    this.snackBar.openFromTemplate(templateMap[nextMessage.templateName],
      {
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
        panelClass: 'my-snackbar',
        duration: 4500,
      })
  }

  private getNextMessage(): NotificationQueueElement | undefined {
    return this.notificationQueue.length ? this.notificationQueue.shift() : undefined;
  }

}
