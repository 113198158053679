import { gql } from 'apollo-angular';


export const TOP_MENU_ITEMS_QUERY = gql`query TopMenuItems($locale: Locale!, $licenseValue: Licence!){
  __type(name: "Countries") {
    enumValues {
      name
    }
  }
  topMenuLists(locales: [$locale, en],where: {AND:{ name: "main", OR: [ {licence: $licenseValue }, { licence: all }] } }) {
    licence
    menuItem {
      inAppUrl
      iconName
      requireLogin
      itemColor{
        hex
      }
      colorName
      name
      page {
          name
          category
          localizedName
        }
      icon{
        url
      }
    }

  }
  pageScripts(locales: [$locale, en]){
    name
    script
  }
  languages(where: {OR: [ {licence: $licenseValue }, { licence: all }]}){
      name
      fullName
      flag{
        url
      }
      licence
    }
    footers(locales: [$locale, en],where: {AND: {name: "main", licence: $licenseValue}}, first:1){
    	name
    	footerLinks:footerLinksNew{
        name
        displayName
        category
        localizedName
      }
      footerExternalLinks(locales: [$locale, en]){
          name
          url
      }
    	licenceDescription
      imageWithLinks{
        name
        url
        image{
          url(transformation: {
            image: { resize: { width: 206, height: 206, fit: clip } }

          })
        }
      }
      licenceLogo{
          url(transformation: {
            image: { resize: { width: 206, height: 206, fit: clip } }

          })
        }
    }
    maintenance(where: {name: "all"}) {
      enabled
    }

  }

  `

