<div [ngClass]="(detectDeviceService.isDesktop()) ? 'desktop': 'mobile'">
  <div class="avatar-category-header">
    <div class="category-header" i18n>select your avatar</div>
    <div class="close-icon">
      <div *ngIf="detectDeviceService.isDesktop() else mobile">
        <app-close-dialog-button [customClass]="'large'" [position]="true" (closeEvent)="closeDialog()"></app-close-dialog-button>
      </div>
    </div>
  </div>
  <div class="avatar-category-userAvatar">
    <div class="thumbnail">
      <img src="{{currentUserAvatar.userAvatar}}" alt="avatar-profile">
    </div>
  </div>
  <div class="avatar-up-arrow-content"></div>
  <div class="avatar-list-container">
    <mat-grid-list cols="3" gutterSize="20px" rowHeight="104px" [responsiveColumns]="avaterListPosition" appResponsiveColumns>
      <mat-grid-tile class="game-tile" *ngFor="let item of avatars ">
        <div class="card" (click)="selectAvatar(item)">
          <div fxLayoutAlign="center center">
            <img [ngStyle]="{'border':currentUserAvatar.userAvatar==item.thumbnail.url ? '4px solid #FFD36C' : 'none'}" src="{{item.thumbnail.url}}" />
          </div>
        </div>
      </mat-grid-tile>
    </mat-grid-list>
  </div>
</div>
<ng-template #mobile>
  <app-close-dialog-button [customClass]="'medium'" [position]="true" (closeEvent)="closeDialog()"></app-close-dialog-button>
</ng-template>