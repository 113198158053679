<div fxLayout="row" fxLayoutAlign="center stretch" fxLayoutGap="2px" class="account-balance-container" *ngIf="balanceInfo">
    <div class="real-balance" fxFlex fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px">
        <div class="balance-icon"><img src="assets/icons/{{ realBalanceValue ? 'icon-wallet-disable' : 'icon-wallet-small' }}.svg" /></div>
        <div fxLayout="column" fxLayoutAlign="start start">
            <div class="info-text" [ngClass]="{'empty-balance': realBalanceValue}" i18n>Real money</div>
            <div class="info-balance" [ngClass]="{'empty-balance': realBalanceValue}" >{{balanceInfo.balance}} {{balanceInfo.currency}}</div>
        </div>
    </div>
    <div class="bonus-balance" fxFlex fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px">
        <div class="balance-icon"><img src="assets/icons/{{ bonusBalanceValue ? 'icon-bonus-disable' : 'icon-bonus' }}.svg" /></div>
        <div fxLayout="column" fxLayoutAlign="start start">
            <div class="info-text" [ngClass]="{'empty-balance': bonusBalanceValue}" i18n>Bonus money</div>
            <div class="info-balance" [ngClass]="{'empty-balance': bonusBalanceValue}" >{{balanceInfo.bonus_balance}} {{balanceInfo.currency}}</div>
        </div>
    </div>
</div>