import { Component, Input } from '@angular/core';
import { DetectDeviceService } from 'src/app/services/utils/detect-device.service';

@Component({
  selector: 'app-header-page-compoanent',
  templateUrl: './header-page-compoanent.component.html',
  styleUrls: ['./header-page-compoanent.component.scss']
})
export class HeaderPageCompoanentComponent {

  @Input() iconName;

}
