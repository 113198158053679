import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginStatusService } from '../../services/auth/login/login-status.service';
import { BestGamesService } from '../../services/client-area/best-games.service';
import { MediaObserver } from '@angular/flex-layout';
import { DetectDeviceService } from '../../services/utils/detect-device.service';
import { OpenDesktopDrawerService } from '../../services/utils/open-desktop-drawer.service';
import { SetMainBackgroundService } from '../../services/desktop/set-main-background.service';
import { ChargebackService } from 'src/app/services/profile/chargeback.service';
import { GamificationLevelService } from 'src/app/services/profile/gamification-level.service';
import { BalanceStatusService } from 'src/app/services/wallet/balance-status.service';
import { ComponentComunicationService } from '../../services/communication/component-comunication.service';
import { NotificationService } from 'src/app/services/notofications/notification.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { FirebaseAuthService } from 'src/app/services/auth/firebase/firebase-auth.service';
import { LastPlayedService } from 'src/app/services/profile/last-played.service';
import { TournamentDetailsService } from 'src/app/services/tournaments/tournament-details.service';
import { UserNotificationService } from 'src/app/services/notofications/user-notification.service';
import { DialogClosePreviousUrl } from 'src/app/services/utils/dialog-close-previouse-url.service';
import { HeadManipulateService } from 'src/app/services/utils/head-manipulate.service';
import { BonusLotteryService } from 'src/app/services/profile/bonuslottery.service';
import { PaymentListService } from 'src/app/services/payment/methods/payment-list.service';
import { UserFullInfoService } from 'src/app/services/auth/check-session/user-full-info.service';
import { BalanceService } from 'src/app/services/wallet/balance.service';
import { BonusListService } from 'src/app/services/payment/bonus/bonus-list.service';
import { UserMessagesService } from 'src/app/services/user-messages/user-messages.service';
import { UserCampaignService } from 'src/app/services/campaigns/campaign.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
  ],
  exports: []
})
export class SharedServicesModule {

  static forRoot(): ModuleWithProviders<SharedServicesModule> {
    return {
      ngModule: SharedServicesModule,
      providers: [
        DialogClosePreviousUrl, DeviceDetectorService, UserNotificationService, FirebaseAuthService, NotificationService, ComponentComunicationService, LoginStatusService, BalanceStatusService, LastPlayedService, BestGamesService, MediaObserver, DetectDeviceService, SetMainBackgroundService, OpenDesktopDrawerService, ChargebackService, GamificationLevelService, TournamentDetailsService,
        HeadManipulateService, NotificationService, BalanceService, PaymentListService, BonusListService, UserFullInfoService, UserMessagesService,
        UserCampaignService

      ],
    }
  }
}
