import { Component, Inject, LOCALE_ID } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { take } from 'rxjs';
import { InAppPageParams } from 'src/app/apollo/models/base-models';
import { HeadManipulateService } from 'src/app/services/utils/head-manipulate.service';
import { ServerSideResponseService } from 'src/app/services/utils/not-found-service';
import { BasePageComponent } from '../base-page/base-page.component';
import { Router } from '@angular/router';
import { LICENCE } from 'src/app/app-routing.module';

export const NOT_FOUND_META = gql`query inAppMetaTags($locale: Locale!, $licenceName: Licence!){
  inAppPagesParams(where: {AND: {siteName:"404", OR: [{licence_contains_some: [all,$licenceName]}] }}){
    seo(locales: [$locale, en]) {
          metaTitle
          metaDescription
          relCanonical
    }
  }
}`;
@Component({
  selector: 'app-mobile-not-found',
  templateUrl: './mobile-not-found.component.html',
  styleUrls: ['./mobile-not-found.component.scss']
})
export class MobileNotFoundComponent extends BasePageComponent {

  constructor(protected ssr: ServerSideResponseService,
    protected manipulateHeaderService: HeadManipulateService,
    @Inject(LOCALE_ID) public locale: string,
    @Inject(LICENCE) public licence: string,
    protected apollo: Apollo,
    protected router: Router
  ) {
    super();
    this.ssr.setNotFound();
    const url = decodeURIComponent(this.router.url);

    if (url !== this.router.url) {
      this.router.navigateByUrl(url);
    }
  }

  protected setTags() {
    const query = NOT_FOUND_META;
    this.apollo
      .watchQuery<{
        inAppPagesParams: InAppPageParams[]
      }>({
        query,
        variables: {
          locale: this.locale,
          licenceName: this.licence,
        }
      })
      .valueChanges.pipe(
        take(1),
      ).subscribe((response) => {
        const inAppparams = response.data.inAppPagesParams.filter(item => !!item.licence?.find(licence => licence === this.licence))[0] || response.data.inAppPagesParams[0]

        this.setSEOTags(inAppparams?.seo?.metaTitle, inAppparams?.seo?.metaDescription, inAppparams?.seo?.relCanonical)
        this.manipulateHeaderService.removeLinks();

      });
  }

  ngOnInit(): void {
    this.setTags();
  }

}
